import { useState } from "react";
import HelpScreen from "../screens/Help";
import Layout from "../layout/Layout";
import Diagnostics from "../screens/Diagnostics";
import NavLayout from "../layout/NavLayout";

type ScreenType = "help" | "diagnostics";
export interface HelpComponentType {
  currentScreenSetter: (screen: ScreenType) => void;
}

export default function HelpNavigator() {
  const [currentScreen, setCurrentScreen] = useState<ScreenType>("help");

  const currentScreenSetter = (screen: ScreenType) => {
    setCurrentScreen(screen);
  };
  let currentScreenJSX;
  switch (currentScreen) {
    case "diagnostics":
      currentScreenJSX = (
        <NavLayout onClickBack={() => setCurrentScreen("help")}>
          <Diagnostics />
        </NavLayout>
      );
      break;
    case "help":
      currentScreenJSX = (
        <HelpScreen currentScreenSetter={currentScreenSetter} />
      );
      break;

    default:
      currentScreenJSX = (
        <HelpScreen currentScreenSetter={currentScreenSetter} />
      );
      break;
  }

  return <Layout>{currentScreenJSX}</Layout>;
}
