import React from "react";
import PropTypes from "prop-types";
import "./details-card.scss";
import martyImg from "../../../assets/assessment/marty.png";

class DetailsCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let detailsParagraphJSX;
    if (this.props.totalScore <= 10) {
      detailsParagraphJSX = (
        <div className="details-paragraph-container">
          <p className="paragraph-title">
            The level of your project is....
          </p>
          <p className="paragraph-intense">BASIC!</p>
          <p className="paragraph">
            You're at the beginning of a great adventure . . . Keep it up!
          </p>
        </div>
      );
    } else if (this.props.totalScore > 10 && this.props.totalScore < 20) {
      detailsParagraphJSX = (
        <div className="details-paragraph-container">
          <p className="paragraph-title">
            The level of your project is....
          </p>
          <p className="paragraph-intense">INTERMEDIATE!</p>
          <p className="paragraph">Excellent work . . . Keep it up!</p>
        </div>
      );
    } else if (this.props.totalScore >= 20) {
      detailsParagraphJSX = (
        <div className="details-paragraph-container">
          <p className="paragraph-title">
            The level of your project is....
          </p>
          <p className="paragraph-intense">ADVANCED!</p>
          <p className="paragraph">You are at the top!</p>
        </div>
      );
    }

    return (
      <div className="details-card">
        <div className="title-row">
          <div className="marty-img-container">
            <img className="marty-img" src={martyImg} alt="marty-img" />
          </div>
          <p>Score: {this.props.totalScore} / 21</p>
        </div>
        <div className="content-row">{detailsParagraphJSX}</div>
      </div>
    );
  }
}

DetailsCard.propTypes = {
  totalScore: PropTypes.number,
};

export default DetailsCard;
