import Abstraction from './models/analyzers/Abstraction';
import BadNaming from './models/analyzers/BadNaming';
import DataRepresentation from './models/analyzers/DataRepresentation';
import DeadCode from './models/analyzers/DeadCode';
import Duplication from './models/analyzers/Duplication';
import FlowControl from './models/analyzers/FlowControl';
import Interactivity from './models/analyzers/Interactivity';
import Logic from './models/analyzers/Logic';
import Parallelism from './models/analyzers/Parallelism';
import Synchronisation from './models/analyzers/Synchronisation';
import AssessmentsManager from './models/AssessmentsManager';
import { Target } from './types/main';

export const assess = (targets: Target[]) => {
  const am = new AssessmentsManager();
  am.registerAnalyzer(new DataRepresentation(targets));
  am.registerAnalyzer(new FlowControl(targets));
  am.registerAnalyzer(new Interactivity(targets));
  am.registerAnalyzer(new Logic(targets));
  am.registerAnalyzer(new Parallelism(targets));
  am.registerAnalyzer(new Synchronisation(targets));
  am.registerAnalyzer(new Abstraction(targets));
  return am.analyze();
};

export const badHabits = (targets: Target[]) => {
  const am = new AssessmentsManager();
  am.registerAnalyzer(new DeadCode(targets))
  am.registerAnalyzer(new Duplication(targets))
  am.registerAnalyzer(new BadNaming(targets))
  return am.analyze();
}

export interface AssessmentScoreInterface {
  abstraction: number;
  dataRepresentation: number;
  flowControl: number;
  interactivity: number;
  logic: number;
  parallelism: number;
  synchronisation: number;
}

export interface BadHabitScoreInterface {
  deadCode: number,
  duplication: number,
  badNaming: number,
}