import martyWaving from "../../../assets/frontMartyWaving.png";
import martyJoints from "../../../assets/martyJoints.png";

export enum Steps {
  STEP01 = 1,
  STEP02 = 2,
  STEP03 = 3,
  STEP04 = 4,
  STEP_EYES_01 = 10,
  STEP_EYES_02 = 11,
  STEP_ARMS_01 = 20,
  STEP_ARMS_02 = 21,
  STEP_LEGS_01 = 30,
  STEP_LEGS_02 = 31,
  STEP_CAL_DONE = 40,
}

export const getCalibrationStrings = (step: number) => {
  switch (step) {
    case Steps.STEP01:
      return {
        title: "First Things First, Let’s Calibrate Marty",
        hint: "Please calibrate all parts of Marty",
      };
    case Steps.STEP_EYES_01:
      return {
        title: `Turn Marty's eyes so that its Eyebrows are flat - horizontal`,
        hint: "Press the button once his eyebrows are flat",
      };
    case Steps.STEP_ARMS_01:
      return {
        title: `Move Marty's arms so they are straight down by its sides`,
        hint: "Press the button once his arms are straight down",
      };
    case Steps.STEP_LEGS_01:
      return {
        title: `Move Marty's legs so that they are straight and his feet face forwards`,
        hint: "Press the button once his legs are straight",
      };
    case Steps.STEP_LEGS_02:
      return {
        title: "Lets test Marty’s movement",
        hint:
          "Press the TEST LEG MOVEMENT button\nthen select whether the test was successful or not.",
      };
    case Steps.STEP_CAL_DONE:
      return {
        title: "Thanks",
        hint: "Press the BACK button to do further caliration or finish up",
      };
    default:
      return { title: "Title", hint: "Hint" };
  }
};

export const getCalibrationButton = (step: number) => {
  switch (step) {
    case Steps.STEP_EYES_01:
      return "Save Calibration for Eyes";
    case Steps.STEP_ARMS_01:
      return "Save Calibration for Arms";
    case Steps.STEP_LEGS_01:
      return "Save Calibration for Legs";
    case Steps.STEP_LEGS_02:
      return "Test Move";
    case Steps.STEP_CAL_DONE:
      return "BACK";
    default:
      return "Thank You";
  }
};

export const getCalibrationImage = (step: number) => {
  switch (step) {
    case Steps.STEP_EYES_01:
    case Steps.STEP_ARMS_01:
    case Steps.STEP_LEGS_01:
      return martyJoints;
    default:
      return martyWaving;
  }
};
