export type UpdateAnalyticsInstanceType = "error" | "success";
// disruptive error means that the update didn't fail due to that error
// but perhaps some file/firmware wasn't updated properly
// prohibiting error means that something went wrong and
// nothing was updated
export type UpdateAnalyticsErrorType = "disruptive" | "prohibiting";
export type UpdateTitleType = "downloadError" | "uploadError" | "ESP32" | "updateFailed" | "hwElem" | "allElemsUpdatedOk" | "someElemsUpdatedOk";
class UpdateInstance {
  public instanceType: UpdateAnalyticsInstanceType;
  public title: UpdateTitleType;
  public msg: string;
  public errorType: UpdateAnalyticsErrorType | undefined;
  public fwUpdateCheckCount: number | undefined;
  public timestamp: string;

  constructor(
    instanceType: UpdateAnalyticsInstanceType,
    title: UpdateTitleType,
    msg: string,
    errorType: UpdateAnalyticsErrorType | undefined,
    fwUpdateCheckCount: number | undefined,
  ) {
      this.instanceType = instanceType;
      this.errorType = errorType;
      this.title = title;
      this.msg = msg;
      this.timestamp = new Date().toISOString();
      this.fwUpdateCheckCount = fwUpdateCheckCount;
  }
}

export default UpdateInstance;
