import Layout from "../../layout/Layout";
import LandingScreenImage from "../../assets/landing-page.png";
import "./styles.css";
import { useEffect } from "react";
import AppSession, { AnalyticsVisitedScreens } from "../../utils/analytics/models/app/AppSession";

function HomeScreen() {
  useEffect(() => {
    // Analytics
    AppSession.getInstance().storeVisitedScreen("home" as AnalyticsVisitedScreens);
  }, [AppSession.getInstance().sessionId]);

  return (
    <Layout>
      <div className="home-screen-image-container">
        <img
          src={LandingScreenImage}
          alt="marty-landing-screen"
          className="home-screen-image"
        />
      </div>
    </Layout>
  );
}

export default HomeScreen;
