/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// FirebaseManager class
// Communicating with the analytics db
//
// (C) Robotical 2022
//
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { AnalyticsConsentCookieType } from "../../analytics/cookies/analytics-consent";
import { DB_URL as APP_DB_URL } from "../app-db-urls";
import { DB_URL as ROBOT_DB_URL } from "../robot-db-urls";

const DB_PICKER = {
  APP_DB_URL,
  ROBOT_DB_URL,
};

type DB_URLS_ARGUMENT_TYPE = "APP_DB_URL" | "ROBOT_DB_URL";

class FirebaseManager {
  private static analyticsConsent: AnalyticsConsentCookieType;

  constructor() {}

  public static setAnalyticsConsent(
    consentDecision: AnalyticsConsentCookieType
  ) {
    FirebaseManager.analyticsConsent = consentDecision;
  }

  public static getAnalyticsConsent() {
    return FirebaseManager.analyticsConsent;
  }

  public static PATCH(
    db: DB_URLS_ARGUMENT_TYPE,
    property: string,
    value: string,
    fbPath: string
  ): Promise<Response> | undefined {
    if (FirebaseManager.analyticsConsent === "negative") return;
    const dataStringified = JSON.stringify({ [property]: value });
    try {
      return fetch(DB_PICKER[db] + fbPath + ".json", {
        method: "PATCH",
        headers: {
          Application: "application/json",
          "Content-Type": "application/json",
        },
        body: dataStringified,
      });
    } catch (err) {
      console.log(err, "FirebaseManager.ts", "line: ", "123");
    }
  }
  
  public static EXISTS(
    db: DB_URLS_ARGUMENT_TYPE,
    fbPath: string = ""
  ): Promise<Response> | undefined {
    try {
      return fetch(DB_PICKER[db] + fbPath + ".json");
    } catch (err) {
      console.log(err, "FirebaseManager.ts", "line: ", "148");
    }
  }

  public static POST(
    db: DB_URLS_ARGUMENT_TYPE,
    data: any,
    fbPath: string = ""
  ): Promise<Response> | undefined {
    if (FirebaseManager.analyticsConsent === "negative") return;
    const dataStringified = JSON.stringify(data);
    try {
      return fetch(DB_PICKER[db] + fbPath + ".json", {
        method: "PATCH",
        headers: {
          Application: "application/json",
          "Content-Type": "application/json",
        },
        body: dataStringified,
      });
    } catch (err) {
      console.log(err, "FirebaseManager.ts", "line: ", "148");
    }
  }
}

export default FirebaseManager;
