import DisconnectConfirmationModal from "../components/modals/DisconnectConfirmation";
import ms from "../state-observables/modal/ModalState";
import connectToMarty from "../utils/network-api/connect-to-marty-function";
import AppConnector from "./AppConnector";
import martyConnector, { MartyConnector } from "./MartyConnector";

export type MartyBlocksIF = {
  accel: string;
  addons: string;
  battRemainCapacityPercent: number;
  isConnected: boolean;
  rssi: number;
  power: string;
  servos: string;
  send_REST: (msg: string) => void;
  streamAudio: (audioData: Uint8Array) => void;
  setBattRemainCapacityPercent: (battRemainCapacityPercent: number) => void;
  setIsConnected: (isConnected: boolean) => void;
  setRSSI: (rssi: number) => void;
  removeEventListeners: () => void;
};
/* 
    Nikos: I had to make public some properties of the MartyConnector class
    because I don't know how to extend from a singleton class.
    Extending from that class would allow me access to its private/protected properites.
*/

class MartyBlocksConnector extends AppConnector {
  protected martyConnector: MartyConnector = martyConnector;
  // Marty Blocks window
  public _martyBlocksIF: MartyBlocksIF | null = null;

  // Sensor readings update timer
  protected _updateTimer: ReturnType<typeof setInterval> | null = null;

  constructor() {
    super();
  }

  async clearUpdater() {
    // busy wait to make sure we clearing
    // the _updateHooks interval after
    // we've updated the last state of marty
    // (marty should have been disconnected by that time)
    await new Promise((r) => setTimeout(r, 200));
    if (this._updateTimer) {
      clearInterval(this._updateTimer);
      this._updateTimer = null;
    }
    this.unhookFromRicConnector();
  }

  protected unhookFromRicConnector() {
    // resetting properties so next time we 
    // fire an event it'll pass through the first
    // check which checks if new property is same 
    // as old property
  if (this._martyBlocksIF) {
      this._martyBlocksIF.battRemainCapacityPercent = 0;
      this._martyBlocksIF.rssi = -200;
      this._martyBlocksIF.isConnected = false;
    }
}

  protected hookupToRicConnector() {
    if (this.martyConnector._ricConnector) {
      // TODO 2022
      if (this._martyBlocksIF) {
        this._martyBlocksIF.setIsConnected(
          this.martyConnector._ricConnector.isConnected()
        );
      }

      // TODO 2022 - need to remove this.martyConnector when disconnected
      if (this._martyBlocksIF) {
        this._martyBlocksIF.send_REST = this.decodeAndSendMsgToApp.bind(
          this
        );
        this._martyBlocksIF.streamAudio = this.martyConnector.streamAudio.bind(
          this.martyConnector
        );
      }
    }
  }


  async decodeAndSendMsgToApp(msg: string) {
    if (msg === "disconnect") {
      return ms.setModal(DisconnectConfirmationModal, "Are you sure you want to disconnect from your Marty?");
    }
    if (msg === "connect") {
      await connectToMarty();
      return this._martyBlocksIF && this.setApp(this._martyBlocksIF);
    }

    return this.martyConnector.sendRestMessage(msg);
  }

  setApp(martyBlocksIF: MartyBlocksIF) {
    // TODO 2022
    this._martyBlocksIF = martyBlocksIF;
    this.hookupToRicConnector();

    if (this.martyConnector._ricConnector.isConnected()) {
      this._updateTimer = setInterval(() => {
       this._updateSensors();
     }, 100);
    }
  }

  _updateSensors() {
    // TODO 2022
    const ricState = this.martyConnector._ricConnector.getRICStateInfo();
    if (this._martyBlocksIF) {
      this._martyBlocksIF.accel = JSON.stringify(ricState.imuData);
      this._martyBlocksIF.addons = JSON.stringify(ricState.addOnInfo);
      this._martyBlocksIF.setIsConnected(
        this.martyConnector._ricConnector.isConnected()
      );
      this._martyBlocksIF.setBattRemainCapacityPercent(
        ricState.power.powerStatus.battRemainCapacityPercent
      );

      this._martyBlocksIF.power = JSON.stringify(ricState.power);
      this._martyBlocksIF.servos = JSON.stringify(ricState.smartServos);

      // TODO 2022 - can rssi be got from WebBLE connection?
      this._martyBlocksIF.setRSSI(this.martyConnector.getRSSI());
    }
  }
}

export default MartyBlocksConnector;
