import Router from "./router";
import "./styles/typography.css";
import { useEffect, useState } from "react";
import Modal from "./components/Modal";
import modalState from "./state-observables/modal/ModalState";
import {
  ModalEventTopics,
  ModalStateData,
} from "./state-observables/modal/ModalObserver";
import analyticsConsentChecking from "./utils/analytics/analytics-consent-checking";

export default function App() {
  const [modalData, setModalData] = useState<null | ModalStateData>(null);
  const [shouldCloseModal, setShouldCloseModal] = useState(false);

  const modalSubscriptionHelper = {
    notify(eventTopic: ModalEventTopics, eventData: ModalStateData) {
      switch (eventTopic) {
        case "SetModal":
          setModalData(eventData);
          break;
        case "CloseModal":
          setShouldCloseModal(true);
          break;
      }
    },
  };

  const resetModal = () => {
    setShouldCloseModal(false);
    setModalData(null);
  };

  // Create subscription to the modal state
  useEffect(() => {
    // Subscribe
    modalState.subscribe(modalSubscriptionHelper, ["SetModal", "CloseModal"]);

    // Return unsubscribe function
    return () => {
      modalState.unsubscribe(modalSubscriptionHelper);
    };
  }, []);


  // checking if there is an analytics consent cookie decision.
  // if there is not, we present the analytics consent modal
  useEffect(() => {
    analyticsConsentChecking();
  }, []);

  return (
    <>
      {!!modalData?.modalContent && (
        <Modal
          title={modalData ? modalData.modalTitle : ""}
          modalResetter={resetModal}
          shouldCloseModal={shouldCloseModal}
          withLogo
        >
          {modalData && modalData.modalContent && <modalData.modalContent />}
        </Modal>
      )}
      <Router />
    </>
  );
}
