import styled, { keyframes } from "styled-components";
import { GREY, GREY_2, WHITE } from "../../../styles/colors";

export const StyledModalBody = styled.div`
  padding-top: 10px;
`;

export const StyledModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
`;

export const StyledModal = styled.div`
  background: ${GREY_2};
  border-radius: 15px;
  padding: 15px;

  .modal-logo-container {
    display: grid;
    justify-items: center;
    margin-bottom: 1rem;
  }
`;
export const StyledModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
`;

export const StyledTitle = styled.h2`
  font-size: 2.5rem;
  font-family: "BigNoodleTitling Regular";
  text-align: center;
`;
