import RC from "@robotical/rc-sequencer/dist/App";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NavLayout from "../../layout/NavLayout";
import martyConnector from "../../MartyConnector/MartyConnector";
import RCSequencerConnector from "../../MartyConnector/RCSequencerConnector";
import AppSession, { AnalyticsVisitedScreens } from "../../utils/analytics/models/app/AppSession";

function RCSequencer() {
  const navigate = useNavigate();

  useEffect(() => {
    // Analytics
    AppSession.getInstance().storeVisitedScreen("controller" as AnalyticsVisitedScreens);
  }, [AppSession.getInstance().sessionId]);

  useEffect(() => {
    const rcSeqConnector = new RCSequencerConnector();
    rcSeqConnector.setApp(window.mv2Sequencer);
    martyConnector.addUpdaterRemover(
      rcSeqConnector.clearUpdater.bind(rcSeqConnector)
    );

    return () => {
      rcSeqConnector.clearUpdater();
    };
  }, []);

  return (
    <NavLayout onClickBack={() => navigate("/")}>
      <RC />
    </NavLayout>
  );
}

export default RCSequencer;
