import MartyBattery from "../../MartyBattery";
import MartySignal from "../../MartySignal";
import { ReactComponent as DisconnectBtnSVG } from "../../../assets/connect-button/disconnect-button.svg";
import { ReactComponent as MartyGraphicSVG } from "../../../assets/connect-button/marty-graphic.svg";

import "./styles.css";
import { useContext, useEffect, useState } from "react";
import martyConnector from "../../../MartyConnector/MartyConnector";
import MartyConnectionContext from "../../../store/marty-connection-context";
import { ReactComponent as QuestionMarkSVG } from "../../../assets/question-mark.svg";
import { RED } from "../../../styles/colors";
import InfoHover from "../../InfoHover";
import { FW_VERSION_RSSI_WIFI } from "../../../utils/wifi-configuration-subtitle-gen";
import isVersionGreater from "../../../utils/helpers/compare-version";
import ReactTooltip from "react-tooltip";

type ButtonDisconnProps = {
  onClick: () => void;
};

export default function ButtonDisconn({ onClick }: ButtonDisconnProps) {
  const [batteryStrength, setBatteryStrength] = useState(0);
  const [RSSI, setRSSI] = useState(-200);
  const connectionCtx = useContext(MartyConnectionContext);

  useEffect(() => {
    const batteryUpdateTimout = setInterval(() => {
      setBatteryStrength(martyConnector.getBatteryStrength());
      setRSSI(martyConnector.getRSSI());
    }, 200);
    return () => clearInterval(batteryUpdateTimout);
  }, []);

  return (
    <div className="button-disconnect-container">
      <ReactTooltip id="signal-info-tooltip" effect="solid" />
      <p className="button-disconnect-title">{connectionCtx.martyName}</p>
      <div className="button-disconnect-battery-container">
        <MartyBattery batteryStrength={batteryStrength} />
      </div>
      <div className="button-disconnect-signal-container">
        <div className="button-disconnect-signal-na">
          <InfoHover
            infoText={
              "Signal strength is not working in this beta version yet."
            }
            tooltipId="signal-info-tooltip"
          >
            {martyConnector.martyVersion &&
              !isVersionGreater(
                martyConnector.martyVersion,
                FW_VERSION_RSSI_WIFI
              ) && <QuestionMarkSVG fill={RED} />}
          </InfoHover>
        </div>
        <MartySignal signalStrength={RSSI} />
      </div>
      <div
        className="button-disconnect-disconnect-btn-container"
        onClick={onClick}
      >
        <DisconnectBtnSVG />
      </div>
      <div className="button-disconnect-marty-container">
        <MartyGraphicSVG />
      </div>
    </div>
  );
}
