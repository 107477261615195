import { Target } from "../../types/main";
import Analyzer from "../Analyzer";

const OPTCODE_RECEIVER = "event_whenbroadcastreceived"
const OPTCODE_BROADCAST = "event_broadcast"
const OPTCODE_BROADCAST_AND_WAIT = "event_broadcastandwait"


interface DeadCodeBlockInfoInterface {
    opcode: string;
    parent: string | null;
    topLevel: boolean;
    id: string;
    reachable: boolean;
    script_id: number;
}

class DeadCode extends Analyzer {
    public targets: Target[];
    public score: number;
    public name: string;
    private totalCodeBlocks: number = 0
    private reachableCodeBlocks: number = 0

    constructor(targets: Target[]) {
        super();
        this.targets = targets;
        this.score = 0;
        this.name = 'DeadCode'
    }

    public execute(): number {
        const codeBlockMap = new Map<string, DeadCodeBlockInfoInterface>()
        const broadcasters: number[] = []
        const receivers: number[] = []

        // In this method, a script is a sequence of code blocks
        // which is linked list, linked via their parent key.
        let primary_script_id = 0
        let script_count = 0

        // Create a map of blocks, and assign each top node a "script_id"
        for (const target of this.targets) {
            for (const _blockKey of Object.keys(target.blocks._blocks)) {
                let script_id = 0
                if (target.blocks._blocks[_blockKey].topLevel) {
                    script_count += 1
                    script_id = script_count

                    // Set a primary script, provided this is not a set of code that is started from a message.
                    // Note, taking a script at random as primary script. Does this matter? How does scratch behave?
                    if (primary_script_id === 0 && target.blocks._blocks[_blockKey].opcode !== OPTCODE_RECEIVER) {
                        primary_script_id = script_id
                    }

                    // Check if this script start with a reciever.
                    if (target.blocks._blocks[_blockKey].opcode === OPTCODE_RECEIVER) {
                        receivers.push(script_id)
                    }
                }

                // Add all the blocks in this target to the map
                codeBlockMap.set(_blockKey, {
                    ...target.blocks._blocks[_blockKey],
                    ...{ reachable: false, script_id: script_id }
                })
            }
        }

        // Ensure every block is part of a script.
        Array.from(codeBlockMap.values()).forEach(value => {
            if (value.script_id === 0) {
                this.walkUp(codeBlockMap, value, broadcasters)
            }
        })

        // When there are broadcasts, then scripts starting with
        // "when I receive" block may not actually indicate dead code.
        // (theoretically we should check that broadcasters are not in dead code!!)
        receivers.forEach((receiver_script_id: number) => {
            // are there any broadcasters (not in the receiver script)
            const broadcasterScriptId = broadcasters.find(broadcaster_id => broadcaster_id !== receiver_script_id)
            if (broadcasterScriptId !== undefined) {
                // Set all code blocks within the receiver script list as reachable.
                Array.from(codeBlockMap.values()).filter(block => block.script_id === receiver_script_id).forEach(value => {
                    value.reachable = true
                })
            }
        })

        // Set all code blocks in the primary script as reachable.
        Array.from(codeBlockMap.values()).filter(block => block.script_id === primary_script_id).forEach(value => {
            value.reachable = true
        })

        // Now we can determine how may code blocks are reachable...
        this.totalCodeBlocks = codeBlockMap.size
        this.reachableCodeBlocks = Array.from(codeBlockMap.values()).filter(block => block.reachable).length

        // return score as a percentage
        if (this.totalCodeBlocks === 0) {
            return 0
        }

        return this.totalCodeBlocks - this.reachableCodeBlocks
    }

    /**
     * Walks up the code block tree until it finds a script id (top level nodes will already have script id set)
     * Then sets the script id of code block as it unwinds down the tree
     */
    private walkUp(map: Map<string, DeadCodeBlockInfoInterface>, node: DeadCodeBlockInfoInterface, broadcasters: number[]): number {

        // If we find node that is already in chain, then just use details from that chain
        // (could be top node!)
        if (node.script_id !== 0) {
            return node.script_id
        }

        if (node.parent !== null) {
            if (map.has(node.parent)) {
                // Get details from parents and set the chain that this node belongs to
                const parent_script_id = this.walkUp(map, (map.get(node.parent) as DeadCodeBlockInfoInterface), broadcasters)
                node.script_id = parent_script_id

                // We also want to determine if a script broadcasts at all
                switch (node.opcode) {
                    case OPTCODE_BROADCAST:
                    case OPTCODE_BROADCAST_AND_WAIT:
                        broadcasters.push(node.script_id)
                        break;
                }
                return parent_script_id
            }
        }
        return 0
    }
}

export default DeadCode;


/**
 * Hair ball...
 *

class DeadCode(HairballPlugin):

    """Plugin that indicates unreachable code in Scratch files."""

    def __init__(self):
        super(DeadCode, self).__init__()
        self.total_instances = 0
        self.dead_code_instances = 0

    def analyze(self, scratch):
        """Run and return the results form the DeadCode plugin.

        The variable_event indicates that the Scratch file contains at least
        one instance of a broadcast event based on a variable. When
        variable_event is True, dead code scripts reported by this plugin that
        begin with a "when I receive" block may not actually indicate dead
        code.

        """
        self.total_instances += 1
        sprites = {}
        for sprite, script in self.iter_sprite_scripts(scratch):
            if not script.reachable:
                blocks_list = []
                for name, _, _ in self.iter_blocks(script.blocks):
                    blocks_list.append(name)
                sprites.setdefault(sprite, []).append(blocks_list)
        if sprites:
            self.dead_code_instances += 1
            import pprint
            pprint.pprint(sprites)
        variable_event = any(True in self.get_broadcast_events(x) for x in
                             self.iter_scripts(scratch))
        return {'dead_code': {'sprites': sprites,
                              'variable_event': variable_event}}

    def finalize(self):
        """Output the number of instances that contained dead code."""

        if self.total_instances > 1:
            print('{0} of {1} instances contained dead code.'
                  .format(self.dead_code_instances, self.total_instances))

 */