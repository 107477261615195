import { RICConnEvent, RICUpdateEvent } from "@robotical/ricjs";
import { useContext, useEffect, useState } from "react";
import martyConnector from "../../../MartyConnector/MartyConnector";
import "./styles.css";
import { ReactComponent as BluetoothSVG } from "../../../assets/configuration/bluetooth.svg";
import { ReactComponent as RefreshSVG } from "../../../assets/refresh.svg";
import MartySignal from "../../MartySignal";
import { MAIN_BLUE, WHITE } from "../../../styles/colors";
import SimpleButton from "../../buttons/SimpleButton";
import SVGImageButton from "../../buttons/SVGImageButton";
import modalState from "../../../state-observables/modal/ModalState";
import connectToMarty from "../../../utils/network-api/connect-to-marty-function";
import MartyConnectionContext from "../../../store/marty-connection-context";
import LEDs from "../../LEDs";
import AppSession, { AnalyticsVisitedScreens } from "../../../utils/analytics/models/app/AppSession";

function VerificationModal() {
  const [randomColours, setRandomColours] = useState<string[]>([]);
  const connectionCtx = useContext(MartyConnectionContext);
  const [RSSI, setRSSI] = useState(-200);
  const [martyName, setMartyName] = useState("");

  const setConnectionLoading = connectionCtx.setIsConnectionLoading;

  useEffect(() => {
    // Analytics
    AppSession.getInstance().storeVisitedScreen("scan" as AnalyticsVisitedScreens);
  }, [AppSession.getInstance().sessionId]);

  useEffect(() => {
    const signalUpdateTimout = setInterval(() => {
      setRSSI(martyConnector.martyRSSI);
    }, 200);
    setMartyName(martyConnector.RICFriendlyName);
    return () => clearInterval(signalUpdateTimout);
  }, []);

  const onCancel = () => {
    // close modal, stop verification process, disconnect
    modalState.closeModal();
    martyConnector.stopVerifyingMarty(false);
    martyConnector.disconnect();
  };

  const onScanAgainOrNo = () => {
    // close modal, stop verification process, disconnect, scan again
    modalState.closeModal();
    martyConnector.stopVerifyingMarty(false);
    martyConnector.disconnect().then((_) => {
      setConnectionLoading(true);
      connectToMarty()
        .then((__) => {
          setConnectionLoading(false);
        })
        .catch((err) => setConnectionLoading(false));
    });
  };

  const onYes = () => {
    // close modal
    modalState.closeModal();
    martyConnector.stopVerifyingMarty(true);
  };

  const martyConnectorSubscriptionHelper = {
    notify(
      eventType: string,
      eventEnum: RICConnEvent | RICUpdateEvent,
      eventName: string,
      eventData: string | object | null | undefined
    ) {
      switch (eventType) {
        case "conn":
          switch (eventEnum) {
            case RICConnEvent.CONN_VERIFYING_CORRECT_RIC:
              setRandomColours(eventData as string[]);
              break;

            default:
              break;
          }
          break;
      }
    },
  };

  // Create subscription to the marty connector state
  useEffect(() => {
    // Subscribe
    martyConnector.subscribe(martyConnectorSubscriptionHelper, ["conn"]);
    // Return unsubscribe function
    return () => {
      martyConnector.unsubscribe(martyConnectorSubscriptionHelper);
    };
  }, []);

  useEffect(() => {
    async function verify() {
      const res = await martyConnector.verifyMarty();
    }

    verify();
  }, []);

  return (
    <div className="verification-modal-container">
      <div className="verification-modal-marty-name-row-container">
        <p className="verification-modal-marty-name">{martyName}</p>
        <div className="verification-modal-marty-signal-container">
          <MartySignal signalStrength={RSSI} />
        </div>
        <BluetoothSVG fill={MAIN_BLUE} />
      </div>
      <p className="verification-modal-martys-back-hint">
        Look on Marty's back, is it displaying these lights?
      </p>
      <div className="verification-modal-led-row-container">
        <LEDs coloursArr={randomColours} />
        <SimpleButton
          onClick={onScanAgainOrNo}
          title="No"
          borderColour="red"
          colour="white"
          textColour="red"
        />
        <SimpleButton onClick={onYes} title="YES" />
      </div>
      <div className="verification-modal-bottom-btns-container">
        <SVGImageButton
          onClick={onScanAgainOrNo}
          title="SCAN AGAIN"
          SVGImage={RefreshSVG}
          backgroundColour={MAIN_BLUE}
          titleColour={WHITE}
          SVGColour={WHITE}
        />
        <div className="dummy-gap"></div>
        <SimpleButton onClick={onCancel} title="CANCEL" />
      </div>
    </div>
  );
}

export default VerificationModal;
