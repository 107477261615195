import { addDoc, collection } from "@firebase/firestore";
import { createCourseStruct } from "./Course";
import { getFirestoreDb, getFirestoreStruct } from "./Firestore";


export const COLLECTION_COURSE_ENJOYMENT = "CourseEnjoyment";

/**
 * Data structure stored to the firstore database.
 */
export interface CourseEnjoymentInterface {
    courseId: string;
    studentId: string;
    ratedAt: Date;
    rating: number;
    enjoymentRequestId: string;
}

export const createCourseEnjoymentStruct = (): CourseEnjoymentInterface => {
    return {
        courseId: "",
        studentId: "",
        ratedAt: new Date(),
        rating: 0,
        enjoymentRequestId: "",
    }
}

export const createCourseEnjoyment = (struct: CourseEnjoymentInterface) => {
    const enjoyment = new CourseEnjoyment()
    enjoyment.fillFromCourseEnjoymentInterface(struct)
    return enjoyment
}

export class CourseEnjoyment implements CourseEnjoymentInterface {
    public courseId = "";
    public studentId = "";
    public ratedAt = new Date();
    public rating = 0;
    public enjoymentRequestId = "";

    public fillFromCourseEnjoymentInterface(courseRatingInterface: CourseEnjoymentInterface) {
        Object.assign(this, courseRatingInterface)
    }
}

export const saveCourseRating = async (rating: CourseEnjoymentInterface) => {
    if (rating.courseId == "") {
        throw "Save Course Rating - Course Id has not been set";
    }
    if (rating.studentId == "") {
        throw "Save Course Rating - Student Id has not been set";
    }
    if (rating.enjoymentRequestId == "") {
        throw "Save Course Rating - Enjoyment request has not been set";
    }

    // Create the record for db
    const record = getFirestoreStruct(createCourseEnjoymentStruct(), rating)
    const db = getFirestoreDb();
    const docRef = await addDoc(collection(db, COLLECTION_COURSE_ENJOYMENT), record);
    return docRef.id
}
