import { RICWifiConnState } from "@robotical/ricjs";
import martyConnector from "../MartyConnector/MartyConnector";
import isVersionGreater from "./helpers/compare-version";

export const FW_VERSION_RSSI_WIFI = "1.1.17"; // greater versions than this (1.1.17) have the wifi rssi logic
export default function wifiConfigSub() {
  if (!isVersionGreater(martyConnector.martyVersion, FW_VERSION_RSSI_WIFI)) {
    return "Currently, wifi configuration only works in the phone application.";
  }
  const isPaused = martyConnector.getCachedWifiStatus().ssid ? true : false;
  const conInfo =
    martyConnector.getCachedWifiStatus().connState ===
      RICWifiConnState.WIFI_CONN_CONNECTED ||
    martyConnector.getCachedWifiStatus().ssid
      ? martyConnector.getCachedWifiStatus().ipAddress +
        " on " +
        martyConnector.getCachedWifiStatus().ssid
      : null;

  const isConnected =
    martyConnector.getCachedWifiStatus().connState ===
    RICWifiConnState.WIFI_CONN_CONNECTED;

  let subtitle;

  if (isConnected) {
    subtitle = "Connected";
  }
  if (isPaused) {
    subtitle = "Connected (Paused for BLE)";
  }
  if (!isConnected && !isPaused) {
    subtitle = "Not Connected";
  }
  if (conInfo) {
    subtitle += " | " + conInfo;
  }

  return subtitle;
}
