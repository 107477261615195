import { ChangeEvent, useEffect, useState } from "react"
import { createAssessmentLogin } from "../../AutomatedAssessment/user/AssessmentUser"
import { CourseInterface } from "../../AutomatedAssessment/firestore/Course";
import { useStudent, SET_COURSE, CLEAR_COURSE } from "../../store/student-context"
import "./styles.scss"
import EnjoymentQuery from "../TeacherMessages/EnjoymentQuery";

const CourseSelect = () => {
    const [busy, setBusy] = useState<boolean>(true);
    const [courseList, setCourseList] = useState<CourseInterface[]>([]);

    const { state: userState, dispatch: studentDispatcher } = useStudent()

    useEffect(() => {
        // Get course list
        const loginHandler = createAssessmentLogin()
        loginHandler.runningCourseList().then((list) => {
            setCourseList(list)
            setBusy(false)
        })
    }, []);

    const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value == "logout") {
            const loginHandler = createAssessmentLogin();
            loginHandler.signOut()
        } else {
            const selectedCourseId = event.target.value
            const course = courseList.find((item) => item.courseId == selectedCourseId)
            if (course == undefined) {
                studentDispatcher({ type: CLEAR_COURSE })
            } else {
                studentDispatcher({ type: SET_COURSE, payload: course })
            }
        }
    }

    const [vis, setVis] = useState<boolean>(false);

    const handleCallBack = () => {
        console.log("Called back")
    }

    const showModal = () => {
        console.log("change state", vis)
        setVis(!vis)
    }

    const closeModal = () => {
        setVis(true)
    }
    //-----------------------------

    const selectedId = userState.currentCourse?.courseId
    return (
        <>
            <div className="course-select-container" >
                {busy && <div>Getting courses...</div>}
                {!busy && <select onChange={handleSelect} value={selectedId}>
                    <option>--- Select Course ---</option>

                    {courseList.map((course) => {
                        return <option key={course.courseId} value={course.courseId} >{course.name}</option>
                    })}

                    <option className="course-select-divider">&nbsp;</option>
                    <option value="logout" >Logout</option>
                </select>
                }
            </div>
        </>
    )
}


export default CourseSelect