/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// AppSession singleton class
// Responsible for managing App
// sessions (collecting and storing info)
// Communicating with the analytics db
//
// (C) Robotical 2022
//
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import randomHashGenerator from "../../../helpers/randomHashGenerator";
import Bowser from "bowser";

import {
  robotSessionsUrlBuilder,
  sessionTitleBuilder,
  visitedScreensUrlBuilder,
} from "../../app-db-urls";
import { getStoredDeviceId, setStoredDeviceId } from "../../cookies/device-id";
import FirebaseManager from "../FirebaseManager";

export type AnalyticsVisitedScreens =
  | "home"
  | "scan"
  | "new-marty"
  | "help-and-support"
  | "marty-connected"
  | "scratchJr"
  | "scratch"
  | "introduction"
  | "build-guide"
  | "diagnostics"
  | "add-ons"
  | "calibration"
  | "controller"
  | "marty-configuration"
  | "marty-name"
  | "marty-update"
  | "video-guide"
  | "unplugged"
  | "wifi-configuration";

type DataFormatType = {
  firstTimestamp: string;
  lastTimestamp: string;
  deviceId: string;
  appVersion: string;
  deviceVersion: string;
  deviceOS: string;
  sessionId: string;
};

export default class AppSession {
  private static instance: AppSession | null = null;
  private deviceId!: string; // stored cookie
  private deviceOS!: string;
  private deviceVersion!: string;
  private lastTimestamp: Date | null = null;
  private lastStoredScreenTimestamp: Date | null = null;
  public sessionId!: string;
  private heartbeat: NodeJS.Timeout | undefined;
  private appVersion!: string;
  private heartbeatInterval: number = 60000;
  private maxIntervalBetweenSessions: number = 3600000; // 1 hour
  private maxIntervalBetweenStoredScreens: number = 200; // 1 hour

  constructor() {}

  public static getInstance() {
    // this class is singleton: only one instance should exist across
    // the whole app. The getInstance method either returns the
    // instance of the class (if it exists) or creates one;
    if (this.instance) return this.instance;
    const newInstance = new AppSession();
    this.instance = newInstance;
    return newInstance;
  }

  private async setDeviceId() {
    // looking if there exists a relevant cookie
    try {
      const fetchedDeviceId = await getStoredDeviceId();
      if (!fetchedDeviceId) {
        // id doesn't exist - storing it
        const randomDeviceId = randomHashGenerator(10);
        setStoredDeviceId(randomDeviceId);
        this.deviceId = randomDeviceId;
      } else {
        // id does exist
        this.deviceId = fetchedDeviceId;
      }
    } catch (error) {
      console.log(error, "Error in AppSession.ts, line 79");
    }
  }

  private setAppVersion(av: string) {
    this.appVersion = av;
  }

  private setDeviceOs() {
    this.deviceOS = "web-app"
  }

  private setDeviceVersion() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const browserInfo = browser.getBrowser();
    this.deviceVersion = browserInfo.name + " -- " + browserInfo.version;
  }

  private async prepareProperties(appVersion: string) {
    this.setAppVersion(appVersion);
    this.setDeviceOs();
    this.setDeviceVersion();
    await this.setDeviceId();
    return Promise.resolve("");
  }

  private setHeartbeat() {
    // every this.heartbeatInterval we sent a new timestamp
    // to the db. In that way we can estimate the time
    // marty was used.

    // Heartbeat is only sent when the app is active.
    // We will take advantage of that and we will use
    // it to decide if we should create a new session
    // or no (if the app was inactive for more than say
    // an hour, then we can categorise the new activity
    // as a new session).
    if (!this.heartbeat) {
      // if there is no heartbeat we just
      // set it up
      this.heartbeat = setInterval(() => {
        // decide if we should start a new session or
        // we continue
        const newLastTimestamp = new Date();
        if (
          this.lastTimestamp &&
          newLastTimestamp.getTime() - this.lastTimestamp.getTime() >
            this.maxIntervalBetweenSessions
        ) {
          // the app was inactive long enough
          // removing the old session and starting a new one
          this.stopHeartbeat();
          this.startSession(this.appVersion);
        } else {
          // we are still in the same session, update heartbeat
          this.lastTimestamp = newLastTimestamp;
          const fbPath = sessionTitleBuilder(this.sessionId);
          // checking if the fbPath exists in the database, if not then we won't send a new lastTimestamp.
          // This is probably a development artefact
          FirebaseManager.EXISTS("APP_DB_URL", fbPath)
            ?.then((res) => res.json())
            .then((r) => {
              if (r) {
                // send heartbeat only if session already exists
                FirebaseManager.PATCH(
                  "APP_DB_URL",
                  "lastTimestamp",
                  this.lastTimestamp!.toISOString(),
                  fbPath
                )?.catch((err) =>
                  console.log(err, "FirebaseManager.ts", "line: ", "103")
                );
              }
            });
        }
      }, this.heartbeatInterval);
    }
  }

  public stopHeartbeat() {
    // stopping repeatedly sending msgs to the db
    if (this.heartbeat) {
      clearInterval(this.heartbeat);
    }
    this.lastTimestamp = null;
    this.heartbeat = undefined;
  }

  public startSession(appVersion: string) {
    // making sure the rest of the app runs
    // even if we are still trying to
    // asynchronously get the device id
    this.prepareProperties(appVersion)
      .then((_) => {
        // The app was just started. Starting session
        this.sessionId = randomHashGenerator();
        // safety net: making sure we only post when
        // the serial number is defined
        if (this.deviceId) {
          this.lastTimestamp = new Date();
          const data: DataFormatType = {
            sessionId: this.sessionId,
            firstTimestamp: this.lastTimestamp.toISOString(),
            lastTimestamp: new Date().toISOString(),
            deviceId: this.deviceId,
            appVersion: this.appVersion,
            deviceOS: this.deviceOS,
            deviceVersion: this.deviceVersion,
          };
          // sending the initial data. when the initial data are sent
          // we set up a heartbeat
          FirebaseManager.POST(
            "APP_DB_URL",
            data,
            sessionTitleBuilder(this.sessionId)
          )
            ?.then((res: Response) => {
              if (res.ok) {
                this.setHeartbeat();
              }
            })
            .catch((err) => console.log(err, "AppSession.ts", "line: ", "154"));
        }
      })
      .catch((err) => console.log(err, "AppSession.ts", "line: ", "157"));
  }

  public storeVisitedScreen(visitedScreen: AnalyticsVisitedScreens) {
    if (!this.sessionId) return; // making sure we only store if we have a sessionId;
    const timestamp = new Date();
    if (
      // storing if the lastStoredScreenTimestamp is null
      // because that means that it's the first time we store that screen
      !this.lastStoredScreenTimestamp ||
      // storing screen only after 'this.maxIntervalBetweenStoredScreens' from the previous recorded screen
      timestamp.getTime() - this.lastStoredScreenTimestamp.getTime() >
        this.maxIntervalBetweenStoredScreens
    ) {
      this.lastStoredScreenTimestamp = timestamp;
      FirebaseManager.POST(
        "APP_DB_URL",
        { timestamp: timestamp.toISOString(), screenId: visitedScreen },
        visitedScreensUrlBuilder(this.sessionId, visitedScreen)
      )
        ?.then((res: Response) => {
          if (res.ok) {
            this.setHeartbeat();
          }
        })
        .catch((err) => console.log(err, "AppSession.ts", "line: ", "174"));
    }
  }

  public storeRobotSession(robotSessionId: string) {
    const data = {
      timestamp: new Date().toISOString(),
      sessionId: robotSessionId,
    };
    FirebaseManager.POST(
      "APP_DB_URL",
      data,
      robotSessionsUrlBuilder(this.sessionId, robotSessionId)
    )
      ?.then((res: Response) => {
        if (res.ok) {
          this.setHeartbeat();
        }
      })
      .catch((err) => console.log(err, "AppSession.ts", "line: ", "174"));
  }
}
