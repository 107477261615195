import styled from "styled-components";

export const EnjoymentQueryContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
`
export const EnjoyModal = styled.div`
    background: var(--colour-GREY_2);
    border-radius: 15px;
    padding: 15px;
`


export const EnjoyModalHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    font-size: 25px;
`

export const EnjoyModalBody = styled.div`
    padding-top: 10px;

    >div {
        margin: 6px 0;
`

export const EnjoyTitle = styled.div`
    font-size: 2.5rem;
    font-family: "BigNoodleTitling Regular";
    text-align: center;
`

export const EnjoyRow = styled.div`
    display: flex;
    justify-content: center;
`

export const EnjoyButton = styled.div`
    cursor: pointer;
    padding: 6px;
`

export const EnjoyImg = styled.img`
    width: 4rem;
`

export const EnjoyModalFooter = styled.div`
    display: flex;
    justify-content: right;
    min-height: 38px;
`

export const EnjoyError = styled.div`
    background: var(--colour-RED);
    padding: 12px;
    font-size: 1.1em;
    color: White;
`

export const EnjoySubmitButton = styled.button`
    background: var(--colour-MAIN_BLUE);
    border-radius: 6px;
    font-size: 1.1em;
    padding: 6px;
    min-height: 32px;
    width: 150px;
`
