import "./styles.css";
import {ReactComponent as BackSVG} from "../../assets/back-button.svg";

type NavLayoutProps = {
  children: React.ReactNode | React.ReactNode[];
  onClickBack: () => void;
}; 
export default function NavLayout({ children, onClickBack }: NavLayoutProps) {
  return (
    <div className="nav-layout-container">
      <div className="nav-layout-go-back-btn" onClick={onClickBack}>
        <BackSVG />
      </div>
      {children}
    </div>
  );
}
