import { getFirestoreDb, getFirestoreStruct, extractStructFromFireStoreDoc } from "./Firestore";
import { getDoc, doc, setDoc } from "@firebase/firestore";
import dayjs from "dayjs";
import {  EnjoymentRequest } from "./EnjoymentRequest";

export const COLLECTION_COURSES = "Courses";

/**
 * Data structure stored to the firstore database.
 */
export interface CourseInterface {
    courseId: string;
    name: string;
    pausedAt: Date;
    readonly enjoymentRequestId: string; // Most recent enjoyment request issued for this course
    readonly enjoymentRequestAt: Date;   // Time that the most recent enjoyment request was issued.
}

/**
 * Provides a blank course interface object
 */
export function createCourseStruct(): CourseInterface {
    const unSetDate = dayjs('2000/01/01').toDate();
    return {
        courseId: "",
        name: "",
        pausedAt: unSetDate,
        enjoymentRequestId: "",
        enjoymentRequestAt: unSetDate
    }
}

/**
 * Create a full course class from the course database structure.
 */
export function createCourse(courseInterface: CourseInterface): Course {
    const course = new Course()
    course.fillFromCourseInterface(courseInterface)
    return course
}

export class Course implements CourseInterface {
    readonly PAUSE_TIME_MINUTES = 60;
    readonly ENJOYMENT_REQUEST_MINUTES = 60;

    public courseId: string = ""
    public name: string = ""
    public pausedAt: Date

    private _enjoymentRequestId: string = ""
    private _enjoymentRequestAt: Date

    constructor() {
        // By default we don't want the course paused, so set paused-at in the past.
        this.pausedAt = this.unSetDate
        this._enjoymentRequestAt = this.unSetDate
    }

    public fillFromCourseInterface(courseInterface: CourseInterface) {
        this.courseId = courseInterface.courseId
        this.name = courseInterface.name
        this.pausedAt = courseInterface.pausedAt
        this._enjoymentRequestId = courseInterface.enjoymentRequestId
        this._enjoymentRequestAt = courseInterface.enjoymentRequestAt
    }

    get isPaused(): boolean {
        // Course is paused if the paused-at time is within the last few minutes.
        const paused = dayjs(this.pausedAt);
        const now = dayjs();
        return now.diff(paused, "minutes") < this.PAUSE_TIME_MINUTES;
    }

    set isPaused(paused_flag: boolean) {
        if (paused_flag) {
            this.pausedAt = new Date();
        } else {
            this.pausedAt = this.unSetDate
        }
    }

    get enjoymentRequestAt(): Date {
        return this._enjoymentRequestAt
    }

    get enjoymentRequestId(): string {
        return this._enjoymentRequestId
    }

    private get unSetDate(): Date {
        return dayjs('2000/01/01').toDate();
    }

    addEnjoymentRequest(requestId: string, request: EnjoymentRequest) {
        this._enjoymentRequestId = requestId
        this._enjoymentRequestAt = request.requestedAt
    }

    get enjoymentRequestIssued(): boolean {
        if (this.enjoymentRequestId == "") {
            console.log("no request id")
            return false
        }
        // Enjoyment request only value for a fixed period after it was issued.
        const requested = dayjs(this._enjoymentRequestAt);
        const now = dayjs();

        console.log("Diff: " + now.diff(requested, "minutes"))
        return now.diff(requested, "minutes") < this.ENJOYMENT_REQUEST_MINUTES;
    }
}

export function saveCourse(course: CourseInterface) {
    if (course.courseId == "") {
        throw "Save Course - Course Id has not been set";
    }

    // Create data field for db
    const courseData = getFirestoreStruct(createCourseStruct(), course)

    const db = getFirestoreDb();

    setDoc(doc(db, COLLECTION_COURSES, courseData.courseId), courseData);
}

export async function retrieveCourse(courseId: string): Promise<Course | null> {
    if (courseId == "") {
        return null;
    }
    const db = getFirestoreDb();

    const docRef = doc(db, COLLECTION_COURSES, courseId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        // As record contains dates, we can't just case to structure.
        const struct = extractStructFromFireStoreDoc(docSnap.data(), createCourseStruct());
        return createCourse(struct)
    }
    return null;
}

