// import { ReactChild } from "react";
import { assess } from "../../../AutomatedAssessment/index";
import ScoresCard from "../scores-card/scores-card.jsx";
import "./accessment-container.scss";
import Modal from "../../Modal/index";
import DetailsCard from "../details-card/details-card.jsx";
import BadHabitsCard from "../bad-habit-card/bad-habit-card";
import { AssessmentScoreInterface, BadHabitScoreInterface } from "../../../AutomatedAssessment"
import React, { useState } from "react";
import AbstractionInfo from "../categories-info/abstraction-info";


interface AssessmentComponentPropInterface {
  getBadHabitsScore(): BadHabitScoreInterface,
  getCurrentScore(): AssessmentScoreInterface,
  getTotalScore(): number
}

interface AssessmentProps {
  assessmentScores: AssessmentComponentPropInterface
}
interface OpenModalInterface {
  title: string,
  content: React.ReactChild
}

const AssessmentContainer = ({ assessmentScores }: AssessmentProps) => {
  const [showModal, setShowModal] = useState(false)
  const [infoTitle, setInfoTitle] = useState('')
  const [infoElement, setInfoElement] = useState<React.ReactChild>()

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     scores: assess([]),
  //     showModal: false,
  //     modalData: { content: null, title: "" },
  //   };
  //   closeModal = closeModal.bind(this);
  //   this.openModal = this.openModal.bind(this);
  //   this.totalScore = this.totalScore.bind(this);
  // }
  const scores = assessmentScores.getCurrentScore()
  const badHabits = assessmentScores.getBadHabitsScore()

  const closeModal = () => {
    setShowModal(false)
  }

  const openModal = (content: OpenModalInterface) => {
    setInfoTitle(content.title)
    setInfoElement(content.content)
    setShowModal(true)
  }

  const totalScore = () => {
    return assessmentScores.getTotalScore()
  }

  const modalContent = () => {
    return (
      <>
        <div className="modal-content">
          {infoElement}
        </div>
        <div className="modal-footer">
          <button onClick={closeModal}>Close</button>
        </div>
      </>
    )
  }

  return (
    <div className="assessment-container">
      {showModal && (
        <Modal
          title={infoTitle}
          shouldCloseModal={false}
          modalResetter={closeModal}
        >
          {modalContent()}
        </Modal>
      )}
      <ScoresCard
        onCategoryClick={openModal}
        dataRepresentation={scores.dataRepresentation}
        flowControl={scores.flowControl}
        interactivity={scores.interactivity}
        logic={scores.logic}
        abstraction={scores.abstraction}
        synchronisation={scores.synchronisation}
        parallelism={scores.parallelism}
      />
      <BadHabitsCard
        duplication={badHabits.duplication}
        badNaming={badHabits.badNaming}
        deadCode={badHabits.deadCode}
      />
      <DetailsCard totalScore={totalScore()} />
    </div>
  );

}



export default AssessmentContainer;
