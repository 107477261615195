import { Course } from "./Course"
import { extractStructFromFireStoreDoc, getFirestoreDb } from "./Firestore"
import { doc, getDoc, setDoc } from "@firebase/firestore";

export const COLLECTION_STUDENTS = "Students"

export interface StudentInterface {
    studentId: string,
    name: string,
    updatedAt: Date,
}

export function createStudentStruct(): StudentInterface {
    return new Student()
}

export function createStudent(studentInterface: StudentInterface) {
    const student = new Student()
    student.studentId = studentInterface.studentId
    student.name = studentInterface.name

    return student
}

export class Student implements StudentInterface {
    public studentId: string = ""
    public name: string = ""
    public updatedAt: Date = new Date()
    public course?: Course
}

export function saveStudent(student: Student) {
    if (student.studentId === '') {
        throw new Error("Save Student - Student has not been set for assessment")
    }
    const key = student.studentId

    // Record save information
    student.updatedAt = new Date()

    const studentData = copyStudent(student, {})

    const db = getFirestoreDb();
    setDoc(doc(db, COLLECTION_STUDENTS, key), studentData, { merge: true });
}

function copyStudent(fromStudent: StudentInterface, toStudent: any): StudentInterface {

    toStudent.studentId = fromStudent.studentId
    toStudent.name = fromStudent.name
    toStudent.updatedAt = fromStudent.updatedAt

    return toStudent as StudentInterface
}

export async function retrieveStudent(studentId: string): Promise<Student | null> {
    if (studentId === "") {
        return null;
    }
    const db = getFirestoreDb();

    const docRef = doc(db, COLLECTION_STUDENTS, studentId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        // As record contains dates, we can't just case to structure.
        const struct = extractStructFromFireStoreDoc(docSnap.data(), createStudentStruct());
        return createStudent(struct)
    }
    return null;
}
