import { useEffect } from "react";
import "./styles.scss";
import { useStudent, LOGIN, LOGOUT } from "../../store/student-context";
import CourseSelect from "../CourseSelect";
import { AssessmentUserInterface, createAssessmentLogin } from "../../AutomatedAssessment/user/AssessmentUser";
import { useNavigate } from "react-router-dom";

import { initializeApp } from "@firebase/app";
import { getAuth, GoogleAuthProvider, signInWithCredential } from "@firebase/auth";
import { query, getDocs, doc, onSnapshot, Firestore, getFirestore, setDoc, collection } from "@firebase/firestore";

const NavLoginButton = () => {
    const { state: userState, dispatch: studentDispatcher } = useStudent()
    const navigate = useNavigate();

    useEffect(() => {
        const loginHandler = createAssessmentLogin();

        // This handle the call back from the assessment login module
        // and logs student in/out.
        const loginStatusUpdate = (user: AssessmentUserInterface) => {
            const action = user.isLoggedIn ? LOGIN : LOGOUT;
            studentDispatcher({ type: action, payload: user })
        }
        // Reminder - the user will be automatically logged in, if previously logged in
        loginHandler.init(loginStatusUpdate)

    }, []);

    const handleLogin = () => {
        const loginHandler = createAssessmentLogin();
        loginHandler.signIn()
    }

    return (
        <div className="nav-login-container" >
            <div className="nav-login-image-container">

                {!userState.isLoggedIn &&
                    <img src="/assets/images/btn_google_signin_dark_normal_web.png" onClick={handleLogin} />}

                {userState.isLoggedIn &&
                    <div className="nav-login-user-box">
                        <div>
                            <div className="nav-login-left">
                                <div>{userState.name}</div>
                                <div className="nav-login-classname">{userState.currentCourse?.name}</div>
                            </div>
                            <div className="nav-login-right">
                                {userState.imageUrl && <img src={userState.imageUrl} />}
                            </div>
                        </div>
                        <div className="nav-login-course-wrap">
                            <CourseSelect />
                        </div>
                    </div>
                }
            </div>
            <p>Login</p>
        </div >
    );
};

export default NavLoginButton;
