import martyConnector from "../../MartyConnector/MartyConnector";
import { RICChannelWebBLE } from "@robotical/ricjs";
import getRSSI from "./get-rssi";

async function connectToMarty(onConnectCb?: () => void) {
  // Note that this code is here rather than in the RICChannelWebBLE component
  // because the requestDevice API demands that it be called from a user action
  // and that doesn't seem to work in a library component
  const device = await navigator.bluetooth.requestDevice({
    // acceptAllDevices: true,
    filters: [{ services: [RICChannelWebBLE.RICServiceUUID] }],
    optionalServices: [],
  });
  // await getRSSI(device.id); // this is redundant, since we're now 
  // taking rssi directly from RIC 
  console.log(`Selected device: ${device.name} id ${device.id}`);
  if (await martyConnector.connect("WebBLE", device)) {
    console.log("HomeScreen - Connected to BLE");
    onConnectCb && onConnectCb();
  } else {
    console.log("HomeScreen - Failed to connect to BLE");
  }
}

export default connectToMarty;
