import styled from "styled-components";

export const StyledView = styled.div`
  display: grid;
  grid-template-areas: "modal-title modal-title" "modal-info marty-img" "button button";
  grid-template-columns: 1fr 1fr;
  background-color: white;
  border-radius: 2rem;
  align-items: center;
  max-width: 90vw;
  max-height: 90vh;
  row-gap: 1rem;
`;

export const ModalText = styled.p`
  grid-area: modal-info;
  font-family: "Lato-Regular";
  text-align: center;
`;

export const ModalTitle = styled.p`
  grid-area: modal-title;
  font-size: 2.2rem;
  font-family: "Lato-Regular";
  text-align: center;
`;

export const CenteredView = styled.div`
  grid-area: button;
  flex-direction: row;
`;

export const StyledImage = styled.img`
  grid-area: marty-img;
  max-height: 40vh;
  max-width: 40vw;
  justify-self: center;
`;