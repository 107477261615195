import { ReactComponent as ConnectButtonSVG } from "../../../assets/connect-button/connect-button.svg";
import { ReactComponent as ConnectSilhouetSVG } from "../../../assets/connect-button/connect-silhouet.svg";
import LoadingSpinner from "../../LoadingSpinner";
import "./styles.css";

type ButtonConnProps = {
  onClick: () => void;
  isLoading: boolean;
};

export default function ButtonConn({ onClick, isLoading }: ButtonConnProps) {
  return (
    <div className="button-conn-container" onClick={onClick}>
      <div className="connect-button-svg-container">
        <ConnectButtonSVG />
      </div>
      <div className="connect-silhouet-svg-container">
        {isLoading ? <LoadingSpinner /> : <ConnectSilhouetSVG />}
      </div>
    </div>
  );
}
