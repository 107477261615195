import { RICHWElem } from "@robotical/ricjs/src/RICTypes";

export type MessageObjType = {
  msgType: string;
  msgBody: string;
  IDNo: number | undefined;
  timeReceived: number;
};

class WarningMessageInstance {
    public type: string;
    public title: string;
    public timestamp: string;
    public idNo: number | undefined;
    public motorName: string | undefined;
    public motortype: string | undefined;
    public motorBusName: string | undefined;
    public motorAddr: string | undefined;
    public motorAddrValid: number | undefined;
    public motorIDNo: string | undefined;
    public motorWhoAmI: string | undefined;
    public motorWhoAmITypeCode: string | undefined;
    public motorSN: string | undefined;
    public motorVersionStr: string | undefined;
    public motorCommsOk: number | undefined;

  constructor(messageObj: MessageObjType, motorElem: RICHWElem | undefined) {
    this.type = messageObj.msgType;
    this.title = messageObj.msgBody;
    this.idNo = messageObj.IDNo;
    this.timestamp = new Date().toISOString();
    this.motorName = motorElem?.name;
    this.motortype = motorElem?.type;
    this.motorBusName = motorElem?.busName;
    this.motorAddr = motorElem?.addr;
    this.motorAddrValid = motorElem?.addrValid;
    this.motorIDNo = motorElem?.IDNo;
    this.motorWhoAmI = motorElem?.whoAmI;
    this.motorWhoAmITypeCode = motorElem?.whoAmITypeCode;
    this.motorSN = motorElem?.SN;
    this.motorVersionStr = motorElem?.versionStr;
    this.motorCommsOk = motorElem?.commsOk;
  }
}

export default WarningMessageInstance;
