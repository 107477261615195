import { useState } from "react";
import ArmCalibration from "../components/calibration-screens/ArmCallibration";
import DoneCalibration from "../components/calibration-screens/DoneCalibration";
import EyeCalibration from "../components/calibration-screens/EyeCalibration";
import LegCalibration1 from "../components/calibration-screens/LegCallibration/Calibration1";
import LegCalibration2 from "../components/calibration-screens/LegCallibration/Calibration2";
import CalibrationScreen from "../screens/Calibration";

type ScreenType =
  | "calibration"
  | "eye-calibration"
  | "arm-calibration"
  | "leg-calibration-1"
  | "leg-calibration-2"
  | "done-calibration";
export interface CalibrationComponentType {
  currentScreenSetter: (screen: ScreenType) => void;
}

export default function CalibrationNavigator() {
  const [currentScreen, setCurrentScreen] = useState<ScreenType>("calibration");

  const currentScreenSetter = (screen: ScreenType) => {
    setCurrentScreen(screen);
  };

  let currentScreenJSX;
  switch (currentScreen) {
    case "eye-calibration":
      currentScreenJSX = (
        <EyeCalibration currentScreenSetter={currentScreenSetter} />
      );
      break;

    case "leg-calibration-1":
      currentScreenJSX = (
        <LegCalibration1 currentScreenSetter={currentScreenSetter} />
      );
      break;

    case "leg-calibration-2":
      currentScreenJSX = (
        <LegCalibration2 currentScreenSetter={currentScreenSetter} />
      );
      break;

    case "arm-calibration":
      currentScreenJSX = (
        <ArmCalibration currentScreenSetter={currentScreenSetter} />
      );
      break;

    case "done-calibration":
      currentScreenJSX = (
        <DoneCalibration currentScreenSetter={currentScreenSetter} />
      );
      break;

    default:
      currentScreenJSX = (
        <CalibrationScreen currentScreenSetter={currentScreenSetter} />
      );
      break;
  }

  return currentScreenJSX;
}
