import { Target } from '../types/main';

abstract class Analyzer {
    abstract name: string;
  abstract score: number;
  abstract targets: Target[];

  public abstract execute(): number;
}

export default Analyzer;
