import { useEffect } from "react";
import AnalyticsConsentModal from "../../components/modals/AnalyticsConsentModal";
import BugReportModal from "../../components/modals/BugReportModal";
import SimpleButton from "../../components/buttons/SimpleButton";
import HelpScreenText from "../../components/HelpScreenText";
import Logo from "../../components/Logo";
import { HelpComponentType } from "../../navigation/HelpNavigator";
import modalState from "../../state-observables/modal/ModalState";
import AppSession, { AnalyticsVisitedScreens } from "../../utils/analytics/models/app/AppSession";
import openNewTab from "../../utils/open-new-tab";
import "./styles.css";

interface HelpScreenProps extends HelpComponentType {}

export default function HelpScreen({ currentScreenSetter }: HelpScreenProps) {
  useEffect(() => {
    // Analytics
    AppSession.getInstance().storeVisitedScreen("help-and-support" as AnalyticsVisitedScreens);
  }, [AppSession.getInstance().sessionId]);

  return (
    <div className="help-screen-container">
      <div className="help-screen-logo-container">
        <Logo />
      </div>
      <div className="help-screen-main-text-container">
        <HelpScreenText />
      </div>
      <div className="help-screen-buttons-container">
        <SimpleButton
          onClick={() =>
            openNewTab("https://userguides.robotical.io/martyv2/support_faqs")
          }
          title="OPEN FAQ"
        />
        <SimpleButton
          onClick={() => openNewTab("https://robotical.io/privacy-policy/")}
          title="PRIVACY POLICY"
        />
        <SimpleButton
          onClick={() => modalState.setModal(AnalyticsConsentModal, "HELP US IMPROVE MARTY")}
          title="DATA ANALYTICS"
        />
        <SimpleButton
          onClick={() => currentScreenSetter("diagnostics")}
          title="CHECK DIAGNOSTICS"
        />
        <SimpleButton title="Bug Report" onClick={() => modalState.setModal(BugReportModal, "Anonymous Bug Report")}/>
      </div>
    </div>
  );
}
