import StoreEventEmitter from "./StoreEventEmitter";
import {
  RICOKFail,
  RICConnector,
  RICConnEvent,
  RICUpdateEvent,
  RICWifiConnStatus,
  RICCommsStats,
  RICWifiScanResults,
} from "@robotical/ricjs";
import { MartyObserver } from "./MartyObserver";
import modalState from "../state-observables/modal/ModalState";
import VerificationModal from "../components/modals/VerificationModal";
import AppSession from "../utils/analytics/models/app/AppSession";
import RobotSession from "../utils/analytics/models/robot/RobotSession";
import { RICNotificationsManager } from "./RICNotificationsManager";
import { PystatusMsgType } from "@robotical/ricjs/src/RICTypes";
import InUnpluggedModalContent from "../components/modals/InUnplugged";

// extending from StoreEventEmitter so we can
// access react context/state from here
// (react context is otherwise accessed only
// from within react components)
export class MartyConnector extends StoreEventEmitter {
  // RIC
  public _ricConnector = new RICConnector();

  // Observers
  private _observers: { [key: string]: Array<MartyObserver> } = {};

  // RICNotificationsManager
  private _ricNotificationsManager: RICNotificationsManager = new RICNotificationsManager(
    this
  );

  // Colours to use for LED patterns
  private ledLcdColours = [
    { led: "#202000", lcd: "#FFFF00" },
    { led: "#880000", lcd: "#FF0000" },
    { led: "#000040", lcd: "#0080FF" },
  ];

  // Updater removers: when marty disconnects
  // these functions will clear the time intervals
  // created for updating the sensors
  private updaterRemovers: (() => void)[] = [];

  // Marty name
  public RICFriendlyName: string = "";

  // wifi scan duration
  private _wifiScanDuration = 7000; //ms
  // Marty version
  public martyVersion: string = "";

  // Joint names
  private _jointNames = {
    LeftHip: "LeftHip",
    LeftTwist: "LeftTwist",
    LeftKnee: "LeftKnee",
    RightHip: "RightHip",
    RightTwist: "RightTwist",
    RightKnee: "RightKnee",
    LeftArm: "LeftArm",
    RightArm: "RightArm",
    Eyes: "Eyes",
  };

  // marty signal
  public martyRSSI: number;

  // Constructor
  constructor() {
    super();
    // ---------- Analytics
    const appSession = AppSession.getInstance();
    appSession.startSession("web-app");
    // ----------
    // Subscribe to RICConnector events
    this._ricConnector.setEventListener(
      (
        eventType: string,
        eventEnum: RICConnEvent | RICUpdateEvent,
        eventName: string,
        eventData: string | object | null | undefined
      ) => {
        console.log(
          `eventType: ${eventType} eventEnum: ${eventEnum} eventName: ${eventName} eventData: ${eventData}`
        );
        this.publish(eventType, eventEnum, eventName, eventData);
      }
    );

    this.martyRSSI = -200;
  }

  // Check if connected
  isConnected(): boolean {
    if (this._ricConnector) {
      return this._ricConnector.isConnected();
    }
    return false;
  }

  /**
   * Connect to a RIC
   *
   * @param {string} method - can be "WebBLE" or "WebSocket"
   * @param {string | object} locator - either a string (WebSocket URL) or an object (WebBLE)
   * @returns Promise<boolean>
   *
   */
  async connect(method: string, locator: string | object): Promise<boolean> {
    // Check already connected
    if (this._ricConnector && this._ricConnector.isConnected()) {
      await this._ricConnector.disconnect();
    }

    // Connect to RIC
    let success = await this._ricConnector.connect(method, locator);
    if (!success) {
      console.log("Failed to connect to RIC");
      return false;
    }

    // TODO 2022 - this code would normally run after LED pattern confirmed
    const sysInfoOk = await this._ricConnector.retrieveMartySystemInfo();
    if (!sysInfoOk) {
      // this.emit(RIC_REJECTED)
      return false;
    } else {
      // this.emit(VERIFIED_CORRECT_ricConnector, { systemInfo: this._ricConnectorSystem.getRICSystemInfo() });
    }

    //  -----check if the ric is in unplugged mode------
    const pystatus = await this.sendRestMessage("pystatus");
    // if it's in unplugged mode update the relevant state
    // that shows a modal
    if (pystatus && (pystatus as unknown as PystatusMsgType).running === "screenfree.py") {
      // set modal state to 'in plugged mode'
      modalState.setModal(InUnpluggedModalContent, "Warning!");
      await this._ricConnector.disconnect();
      return false;
    }

    // update components state
    this.connectionCtx?.setIsConnected(true);
    const martyName = await this.getRICName();
    this.connectionCtx?.setMartyName(martyName);

    // start verification process
    modalState.setModal(VerificationModal, "Looking for Marty");
    return true;
  }

  async verifyMarty() {
    return this._ricConnector.checkCorrectRICStart(this.ledLcdColours);
  }

  async stopVerifyingMarty(confirmCorrectRIC: boolean) {
    if (confirmCorrectRIC) {
      // successful connection to Marty
      const ricSystem = this._ricConnector.getRICSystem();

      const systemInfo = await ricSystem.getRICSystemInfo(true);
      // Analytics
      // create new robot session
      const robotSession = RobotSession.getInstance();
      const appSession = AppSession.getInstance();
      this.martyVersion = systemInfo.SystemVersion;
      robotSession.startSession(
        systemInfo.SerialNo!,
        systemInfo.SystemVersion,
        appSession.sessionId
      );
      // pass the robot session id to the app db
      appSession.storeRobotSession(robotSession.sessionId);

      // add a callback to display warning messages from RIC to the user
      this._ricNotificationsManager.setNotificationsHandler(
        this._ricConnector.getRICMsgHandler()
      );
    }
    return this._ricConnector.checkCorrectRICStop(confirmCorrectRIC);
  }

  async getHWElemList() {
    const ricSystem = this._ricConnector.getRICSystem();
    const elemList = await ricSystem.getHWElemList();
    return elemList;
  }

  async getAddOnList() {
    const ricSystem = this._ricConnector.getRICSystem();
    const addOnList = await ricSystem.getAddOnList();
    return addOnList;
  }

  async identifyAddOn(name: string) {
    await this._ricConnector.identifyAddOn(name);
  }

  async setAddOnConfig(serialNo: string, newName: string) {
    await this._ricConnector.setAddOnConfig(serialNo, newName);
  }

  async deleteAddOn(serialNo: string) {
    await this._ricConnector.deleteAddOn(serialNo);
  }

  getCachedRICName() {
    return this.RICFriendlyName;
  }

  async getRICName() {
    const ricSystem = this._ricConnector.getRICSystem();
    const nameObj = await ricSystem.getRICName();
    this.RICFriendlyName = nameObj.friendlyName;
    return nameObj.friendlyName;
  }

  async setRICName(newName: string) {
    try {
      const ricSystem = this._ricConnector.getRICSystem();
      await ricSystem.setRICName(newName);
      this.connectionCtx?.setMartyName(newName);
    } catch (e) {
      console.log("Couldn't set Marty name", e);
    }
  }

  getBatteryStrength() {
    const ricState = this._ricConnector.getRICStateInfo();
    return ricState.power.powerStatus.battRemainCapacityPercent;
  }

  addUpdaterRemover(updaterRemover: () => void) {
    this.updaterRemovers.push(updaterRemover);
  }

  clearUpdatersAfterDisconnect() {
    this.updaterRemovers.forEach((updaterRemover) => updaterRemover());
    this.updaterRemovers = [];
  }

  async disconnect(): Promise<boolean> {
    await this._ricConnector?.disconnect();
    this.connectionCtx?.setIsConnected(false);
    this.clearUpdatersAfterDisconnect();
    return true;
  }

  async sendRestMessage(msg: string, params?: object): Promise<RICOKFail> {
    if (this._ricConnector) {
      return this._ricConnector.sendRICRESTMsg(msg, params || {});
    }
    return new RICOKFail();
  }

  async streamAudio(audioData: Uint8Array): Promise<boolean> {
    console.log(`streamAudio length ${audioData.length}`);
    if (this._ricConnector) {
      this._ricConnector.streamAudio(audioData, true);
      return true;
    }
    return false;
  }

  // Marty observer
  subscribe(observer: MartyObserver, topics: Array<string>): void {
    for (const topic of topics) {
      if (!this._observers[topic]) {
        this._observers[topic] = [];
      }
      if (this._observers[topic].indexOf(observer) === -1) {
        this._observers[topic].push(observer);
      }
    }
  }

  unsubscribe(observer: MartyObserver): void {
    for (const topic in this._observers) {
      if (this._observers.hasOwnProperty(topic)) {
        const index = this._observers[topic].indexOf(observer);
        if (index !== -1) {
          this._observers[topic].splice(index, 1);
        }
      }
    }
  }

  publish(
    eventType: string,
    eventEnum: RICConnEvent | RICUpdateEvent,
    eventName: string,
    eventData: string | object | null | undefined
  ): void {
    if (this._observers.hasOwnProperty(eventType)) {
      for (const observer of this._observers[eventType]) {
        observer.notify(eventType, eventEnum, eventName, eventData);
      }
    }
  }

  // mark: wifi configuration
  getCachedWifiStatus(): RICWifiConnStatus {
    const ricSystem = this._ricConnector.getRICSystem();
    return ricSystem.getCachedWifiStatus();
  }

  // mark: wifi connect
  async wifiConnect(ssid: string, password: string): Promise<boolean> {
    const ricSystem = this._ricConnector.getRICSystem();
    return ricSystem.wifiConnect(ssid, password);
  }

  // mark: wifi disconnect
  async wifiDisconnect(): Promise<boolean> {
    const ricSystem = this._ricConnector.getRICSystem();
    return ricSystem.wifiDisconnect();
  }

  // mark: wifi getWiFiConnStatus
  async getWiFiConnStatus(): Promise<boolean | null> {
    const ricSystem = this._ricConnector.getRICSystem();
    return ricSystem.getWiFiConnStatus();
  }

  // mark: pause wifi
  async pauseWifiConnection(pause: boolean) {
    const ricSystem = this._ricConnector.getRICSystem();
    return ricSystem.pauseWifiConnection(pause);
  }

  // mark: get rssi of connected marty
  getRSSI() {
    const ricState = this._ricConnector.getRICStateInfo();
    const rssiValue = ricState.robotStatus.robotStatus.bleRSSI;
    this.martyRSSI = rssiValue;
    return rssiValue;
  }

  // mark: wifi scan get results
  async wifiScanResults(): Promise<RICWifiScanResults | false> {
    const ricSystem = this._ricConnector.getRICSystem();
    await ricSystem.wifiScanStart();
    let resultsTimeout: NodeJS.Timeout;
    const results: RICOKFail | RICWifiScanResults = await new Promise(
      (resolve, reject) =>
        (resultsTimeout = setTimeout(() => {
          ricSystem.wifiScanResults()
            .then((wifiscanMsgResults: boolean | RICOKFail | RICWifiScanResults) => {
              if (typeof wifiscanMsgResults !== "boolean") {
                resolve(wifiscanMsgResults);
              } else {
                reject("Something went wrong");
              }
            })
            .catch((err) => reject(err))
            .finally(() => clearTimeout(resultsTimeout));
        }, this._wifiScanDuration))
    );
    if ((results as RICWifiScanResults).hasOwnProperty("wifi")) {
      return results as RICWifiScanResults;
    }
    return false;
  }

  // mark: calibration
  async calibrate(cmd: string, joints: string) {
    // Make a list of joints to calibrate on
    const jointList: Array<string> = new Array<string>();
    if (joints === "legs") {
      jointList.push(this._jointNames.LeftHip);
      jointList.push(this._jointNames.LeftTwist);
      jointList.push(this._jointNames.LeftKnee);
      jointList.push(this._jointNames.RightHip);
      jointList.push(this._jointNames.RightTwist);
      jointList.push(this._jointNames.RightKnee);
    } else if (joints === "arms") {
      jointList.push(this._jointNames.LeftArm);
      jointList.push(this._jointNames.RightArm);
    }
    if (joints === "eyes") {
      jointList.push(this._jointNames.Eyes);
    }

    const ricSystem = this._ricConnector.getRICSystem();
    // TODO: REMOVE THE TS-ignore once the newer version of ricjs is published
    //@ts-ignore
    return ricSystem.calibrate(cmd, jointList, this._jointNames);
  }

  // Mark: Comms stats -----------------------------------------------------------------------------------------

  getCommsStats(): RICCommsStats {
    return this._ricConnector.getCommsStats();
  }
}

const martyConnector = new MartyConnector();
export default martyConnector;
