import { RICHWElem, RICMsgHandler } from "@robotical/ricjs";
import InBtButAPressedModalContent from "../components/modals/InBtButAPressed";
import modalState from "../state-observables/modal/ModalState";
import RobotSession from "../utils/analytics/models/robot/RobotSession";
import { MartyConnector } from "./MartyConnector";

export class RICNotificationsManager {
  private martyConnector: MartyConnector;
  constructor(martyConnector: MartyConnector) {
    this.martyConnector = martyConnector;
  }

  setNotificationsHandler(ricMsgHandler: RICMsgHandler) {
    ricMsgHandler.reportMsgCallbacksSet(
      "notifyCB",
      this.reportNofication.bind(this)
    );
  }


  msgBodyDecider(report: any, hwElems: RICHWElem[]) {
    switch (report.msgBody) {
        case "overCurrentDet":
          if (!report.hasOwnProperty("IDNo")) break;
          let motor = report.IDNo;
          const motorElem = hwElems.find(({ IDNo }) => IDNo === report.IDNo);

          if (motorElem !== undefined) {
            motor = motorElem.name;
          }

          // TODO: add warnings manager to display warnings to user
          // adding warning to the manager where the warning message to
          // be displayed is decided
          // const ocwm = OverCurrentWarningManager.getInstanceOrInstantiate();
          // const warning = new OverCurrentWarning(motor);
          // ocwm.addWarning(warning);
          break;

        case "freefallDet": 
          // this.emit(INCREMENT_FREE_FALL_WARNINGS);
          break;

        // Button A pressed Case
        case "btnAPressed":
          modalState.setModal(InBtButAPressedModalContent, "Warning!")
          break;
      }
  }

  // REPORT notifications are messages generated by RIC in response to certain events, including raw i2c events and motor safeties
  reportNofication(report: any): void {
    const ricSystem = this.martyConnector._ricConnector.getRICSystem();
    const hwElems = ricSystem.getCachedHWElemList();

    console.log(`reportNotification Report callback ${JSON.stringify(report)}`);

    if (report.hasOwnProperty("msgType")) {
      if (report.msgType === "raw") {
        // raw messages are the responses to i2c commands, we don't want to tell the user about these
        return;
      }

      if (report.msgType === "warn" && report.hasOwnProperty("msgBody")) {
        // Analytics
        let motorElem;
        if (report.hasOwnProperty("IDNo")) {
          motorElem = hwElems.find(({ IDNo }) => IDNo === report.IDNo);
        }
        RobotSession.getInstance().addWarningMessage(report, motorElem);
        this.msgBodyDecider(report, hwElems);
      }
    }
  }
}
