import { SyntheticEvent, useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom"
import styled from 'styled-components';
import martyConnector from '../../MartyConnector/MartyConnector';
import { MAIN_BLUE } from '../../styles/colors';
import { act } from 'react-dom/test-utils';
import MartyBlocks from '../MartyBlocks/MartyBlocks';
import Student, { StudentDataInterface, createStudentData, createStudentFromData } from '../../utils/Student';
import { useStudent } from '../../store/student-context';
import NavLayout from "../../layout/NavLayout";
import MartyBlocksConnector, { MartyBlocksIF } from '../../MartyConnector/MartyBlocksConnector';
// import { automationGreeter } from "automatic-assessments/lib/automation"
import { getAssessmentCallBackInstance } from "../../AutomatedAssessment/automation/AssessmentHeartBeat"

let myVM;

function DevelopScreen() {
    // const [student, setStudent] = useContext(StudentContext)
    const { state: student} = useStudent()
    const [martyVisible, setMartyVisible] = useState(true)
    const [isMartyBlocksLoaded, setIsMartyBlocksLoaded] = useState(false);
    const navigate = useNavigate();
    const [msg, setMsg] = useState("test")

    const start = {
        message: 'message',
        active: true
    }
    const [assessmentDispatch, setAssessmentDispatch] = useState(start)

    let id = useParams();


    const handleMartyBlocksLoad = (e: SyntheticEvent<HTMLDivElement>) => {
        setIsMartyBlocksLoaded(true);
    };

    useEffect(() => {
        let mbConnector: MartyBlocksConnector;
        const automatedAssessment = getAssessmentCallBackInstance()

        if (isMartyBlocksLoaded) {
            mbConnector = new MartyBlocksConnector();

            // Marty Blocks is not always on Window index 0.
            // Check which window is referencing Marty Blocks
            let mv2: any 
            let vmRuntime: any
            for (let i = 0; i < window.length; i++) {
                try {
                    // If not Marty Blocks, mv2 will not be available and will throw error.
                    mv2 = (window[i as any] as any).mv2
                    vmRuntime = (window[i as any] as any).vm.runtime
                    break;
                } catch {
                    console.log("MV2 not set for window: " + i);
                }
            }

            mbConnector.setApp(mv2 as MartyBlocksIF);
            martyConnector.addUpdaterRemover(
                mbConnector.clearUpdater.bind(mbConnector)
            );

            const courseId: string = student.currentCourse ? student.currentCourse.courseId : ""
            automatedAssessment.startAssessment(student.id, courseId, vmRuntime)
        }
        return () => {
            automatedAssessment.stopAssessment()

            mbConnector && mbConnector.clearUpdater();
        };
    }, [isMartyBlocksLoaded]);


    const studentIn = student.isLoggedIn ? "yes" : "no"

    const handleClickTest = () => {
        const automatedAssessment = getAssessmentCallBackInstance()


        console.log(automatedAssessment.getCurrentScore())

    }

    // const studentIn = "no"

    return (
        <NavLayout onClickBack={() => navigate("/")}>
            <div>
                <button onClick={handleClickTest} >Test</button> Msg: {msg}
            </div>
            <MainWindow>
                <Sidebar>
                    {/* <Assessment student={student} assessment={assessmentDispatch} /> */}
                    {/* <ClassroomMembership /> */}
                </Sidebar>

                <SplashWindow>
                    <object type="text/html" data="./MartyBlocks/index.html" onLoad={handleMartyBlocksLoad} style={{ width: '100%', height: '100%' }}>
                    </object>
                </SplashWindow>
            </MainWindow>
        </NavLayout>
    );
}

const MainWindow = styled.div`
    text-align: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
`

const Sidebar = styled.div`
    flex: 1 1 auto;
    height: 100%;
    max-width: 250px;
    background-color: #f5f5f5;
`

const SplashWindow = styled.div`
    background-color: ${MAIN_BLUE};
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

const SplashImage = styled.img`
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  background-color: ${MAIN_BLUE};
  src: ${props => props.src};
`;

export default DevelopScreen;
