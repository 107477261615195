import randomHashGenerator from "../helpers/randomHashGenerator";
import { AnalyticsVisitedScreens } from "./models/app/AppSession";

const PROJECT_ID = "marty-app-analytics-default-rtdb";
const REGION = "europe-west1";
const ROBOT_SESSIONS = "robotSessions";
const VISITED_SCREENS = "visitedScreens";
const SESSION = "session-";

export const DB_URL = `https://${PROJECT_ID}.${REGION}.firebasedatabase.app/`;

export const sessionTitleBuilder = (sessionId: string) => {
    return SESSION + sessionId;
};

export const robotSessionsUrlBuilder = (appSessionId: string, robotSessionId: string) => {
    return SESSION + appSessionId + "/" + ROBOT_SESSIONS + "/" + SESSION + robotSessionId;
};

export const visitedScreensUrlBuilder = (appSessionId: string, screen: AnalyticsVisitedScreens) => {
    const screenUniqueId = randomHashGenerator(10);
    return SESSION + appSessionId + "/" + VISITED_SCREENS + "/" + screen  + "-" + screenUniqueId; 
};

