import { orderBy, collection, getDocs, query, where } from '@firebase/firestore';
import { Assessment, AssessmentInterface, COLLECTION_ASSESSMENTS, createAssessment, createAssessmentStruct } from './Assessment';
import { extractStructFromFireStoreDoc, getFirestoreDb } from './Firestore';
import { StudentInterface, Student, createStudent, COLLECTION_STUDENTS } from './Student';
import { COLLECTION_ASSESSMENT_HISTORY } from "./AssessmentHistory"
import { COLLECTION_COURSE_ENJOYMENT, CourseEnjoyment, CourseEnjoymentInterface, createCourseEnjoyment, createCourseEnjoymentStruct } from './CourseEnjoyment';
import { createCourseStruct } from './Course';

/**
 * It is possible to get data from Firestore with a one off hit or listen for realtime updates.
 * This file provides methods for one of data retrievals
 */

export async function getStudents(courseId: string) {
    if (courseId === "") {
        return []
    }
    const db = getFirestoreDb()

    const courseCollection = collection(db, COLLECTION_STUDENTS);

    const classroomSnapshot = await getDocs(courseCollection);
    const courseList = classroomSnapshot.docs.map(doc => doc.data());

    return courseList;
}

export async function getAssessments(courseId: string): Promise<Assessment[]> {
    if (courseId === "") {
        return []
    }
    const db = getFirestoreDb()

    const courseCollection = collection(db, COLLECTION_ASSESSMENTS);
    const queryRef = query(courseCollection, where('courseId', '==', courseId))

    const assessmentSnapshot = await getDocs(queryRef);

    const assessmentList = assessmentSnapshot.docs.map(doc => {
        // As record contains dates, we can't just cast to structure.
        const struct = extractStructFromFireStoreDoc(doc.data(), createAssessmentStruct());

        const assessment = new Assessment()
        assessment.fillFromAssessmentInterface(struct);
        return assessment;
    });

    return assessmentList;
}

/**
 * Get list of students corresponding to the list of assessment results passed.
 */
export async function getStudentsForAssessmentList(assessments: Assessment[]): Promise<Student[]> {
    if (assessments.length === 0) {
        return []
    }

    const db = getFirestoreDb()
    const studentIdList: string[] = assessments.map((assessment: Assessment) => assessment.studentId)
    let studentList: Student[] = []

    if (studentIdList.length > 0) {

        const courseCollection = collection(db, COLLECTION_STUDENTS);
        const queryRef = query(courseCollection, where('studentId', 'in', studentIdList))

        const classroomSnapshot = await getDocs(queryRef);


        studentList = classroomSnapshot.docs
            .map(doc => {
                const studentDetail = doc.data() as StudentInterface

                const student = createStudent(studentDetail)
                student.studentId = studentDetail.studentId
                student.name = studentDetail.name
                return student
            });
    }

    // Check a student is returned for every assessment passed
    return assessments.map((assessment: Assessment) => {
        let student = studentList.find((student) => student.studentId === assessment.studentId)
        // No student has been added for this assignment
        if (student === undefined) {
            student = new Student()
            student.studentId = assessment.studentId
            student.name = assessment.studentId
        }
        return student;
    })
}


export async function getAssessmentForStudentCourse(courseId: string, studentId: string): Promise<AssessmentInterface[]> {
    if (courseId === "" || studentId === "") {
        return []
    }
    const db = getFirestoreDb()

    const assessmentCollection = collection(db, COLLECTION_ASSESSMENTS);
    const queryRef = query(assessmentCollection,
        where('studentId', '==', studentId),
        where('courseId', '==', courseId),
    )

    const assessmentSnapshot = await getDocs(queryRef);
    const assessmentList = assessmentSnapshot.docs.map(doc => doc.data() as AssessmentInterface);

    return assessmentList;
}

export async function getAssessmentHistory(courseId: string, since: Date): Promise<Assessment[]> {
    if (courseId === "") {
        return [];
    }
    const db = getFirestoreDb();

    const assessmentCollection = collection(db, COLLECTION_ASSESSMENT_HISTORY);
    const queryRef = query(assessmentCollection, where("courseId", "==", courseId), where("updatedAt", ">", since), orderBy("updatedAt"));

    const assessmentSnapshot = await getDocs(queryRef);
    return assessmentSnapshot.docs.map((doc) => {
        // As record contains dates, we can't just cast to structure.
        const struct = extractStructFromFireStoreDoc(doc.data(), createAssessmentStruct());

        return createAssessment(struct);
    });
}


export async function getStudentAssessmentHistory(courseId: string, studentId: string, since: Date): Promise<Assessment[]> {
    if (courseId === "") {
        return [];
    }
    const db = getFirestoreDb();

    const assessmentCollection = collection(db, COLLECTION_ASSESSMENT_HISTORY);
    const queryRef = query(assessmentCollection,
        where("courseId", "==", courseId),
        where("studentId", "==", studentId),
        where("updatedAt", ">", since),
        orderBy("updatedAt"));

    const assessmentSnapshot = await getDocs(queryRef);
    return assessmentSnapshot.docs.map((doc) => {
        // As record contains dates, we can't just cast to structure.
        const struct = extractStructFromFireStoreDoc(doc.data(), createAssessmentStruct());

        return createAssessment(struct);
    });
}

export async function getCourseRatingForStudent(courseId: string, studentId: string): Promise<CourseEnjoyment[]> {
    if (courseId === "" || studentId === "") {
        return []
    }
    const db = getFirestoreDb()

    const ratingCollection = collection(db, COLLECTION_COURSE_ENJOYMENT);
    const queryRef = query(ratingCollection, where("courseId", "==", courseId), where("studentId", "==", studentId))
    const snapshot = await getDocs(queryRef)

    return snapshot.docs.map((doc) => {
        const rating = new CourseEnjoyment()
        rating.fillFromCourseEnjoymentInterface(doc.data() as CourseEnjoymentInterface)
        return rating;
    })
}

export async function getEnjoymentList(courseId: string, since: Date): Promise<CourseEnjoyment[]> {
    if (courseId === "") {
        return [];
    }
    const db = getFirestoreDb();

    const enjoymentCollection = collection(db, COLLECTION_COURSE_ENJOYMENT);
    const queryRef = query(enjoymentCollection, where("courseId", "==", courseId), where("ratedAt", ">", since), orderBy("ratedAt"));

    const snapShot = await getDocs(queryRef);
    return snapShot.docs.map((doc) => {
        // As record contains dates, we can't just cast to structure.
        const struct = extractStructFromFireStoreDoc(doc.data(), createCourseEnjoymentStruct());

        return createCourseEnjoyment(struct);
    });
}

export async function getStudentEnjoymentList(courseId: string, studentId: string, since: Date): Promise<CourseEnjoyment[]> {
    if (courseId === "") {
        return [];
    }
    const db = getFirestoreDb();

    const enjoymentCollection = collection(db, COLLECTION_COURSE_ENJOYMENT);
    const queryRef = query(enjoymentCollection, where("courseId", "==", courseId),
        where("studentId", "==", studentId),
        where("ratedAt", ">", since),
        orderBy("ratedAt")
    );

    const snapShot = await getDocs(queryRef);
    return snapShot.docs.map((doc) => {
        // As record contains dates, we can't just cast to structure.
        const struct = extractStructFromFireStoreDoc(doc.data(), createCourseEnjoymentStruct());

        return createCourseEnjoyment(struct);
    });
}