import * as React from 'react'
import { Assessment, saveAssessment } from '../AutomatedAssessment/firestore/Assessment'
import { Course, CourseInterface, createCourse, saveCourse } from '../AutomatedAssessment/firestore/Course'
import { usingFirebase } from '../AutomatedAssessment/firestore/Firestore'
import { getAssessmentForStudentCourse } from '../AutomatedAssessment/firestore/FirestoreQuery'
import { saveStudent, Student } from '../AutomatedAssessment/firestore/Student'
import { AssessmentUserInterface, createAssessmentUser } from '../AutomatedAssessment/user/AssessmentUser'

export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const SET_COURSE = 'set_course'
export const CLEAR_COURSE = 'clear_course'

type ActionType = 'login' | 'logout' | 'set_course' | 'clear_course'

type Action = { type: ActionType, payload?: any }
type Dispatch = (action: Action) => void
type StudentProviderProps = { children: React.ReactNode }


const StudentContext = React.createContext<
    { state: AssessmentUserInterface; dispatch: Dispatch } | undefined
>(undefined)

function userReducer(state: AssessmentUserInterface, action: Action) {
    switch (action.type) {
        case LOGIN: {
            // Ensure this student is saved to database
            const user = action.payload as AssessmentUserInterface;
            const student = new Student()
            student.studentId = user.id
            student.name = user.name
            usingFirebase().then(() => saveStudent(student))
            return { ...state, ...action.payload }
        }
        case LOGOUT: {
            // create a blank user to logout..
            return createAssessmentUser()
        }
        case SET_COURSE: {
            const course = action.payload as CourseInterface
            const classroomUpdate = {
                currentCourse: course,
            }
            ensureAssessmentExists(course, state)

            return { ...state, ...classroomUpdate }
        }
        case CLEAR_COURSE: {
            const updatedUser = { ...state }
            delete updatedUser.currentCourse
            return updatedUser
        }
        default: {
            throw new Error(`Unhandled action type: Action Type`)
        }
    }
}

function StudentContextProvider({ children }: StudentProviderProps) {
    const [state, dispatch] = React.useReducer(userReducer, createAssessmentUser())
    const value = { state, dispatch }
    return <StudentContext.Provider value={value}>{children}</StudentContext.Provider>
}

function useStudent() {
    const context = React.useContext(StudentContext)
    if (context === undefined) {
        throw new Error('useStudent must be used within a StudentProvider')
    }
    return context
}

/**
 * Add an assessment record for a course/student if one does not exist
 * 
 */
function ensureAssessmentExists(course: CourseInterface, student: AssessmentUserInterface) {
    usingFirebase().then(() => {
        getAssessmentForStudentCourse(course.courseId, student.id).then((list) => {

            if (list.length == 0) {
                const assessment = new Assessment()
                assessment.courseId = course.courseId
                assessment.courseName = course.name
                assessment.studentId = student.id
                assessment.studentName = student.name
                saveAssessment(assessment)
            }
        })

    })
}

export { StudentContextProvider, useStudent }
