import { getFirestoreDb, extractStructFromFireStoreDoc, } from "./Firestore";
import { doc, onSnapshot, Firestore, getFirestore, collection, query, where, getDocs, collectionGroup, Unsubscribe } from "@firebase/firestore";
import { Course, COLLECTION_COURSES, CourseInterface, createCourse, createCourseStruct } from "./Course";
import { Assessment, AssessmentInterface, COLLECTION_ASSESSMENTS, createAssessmentStruct } from "./Assessment";
import { Student, COLLECTION_STUDENTS } from "./Student";
import { Timestamp } from "@firebase/firestore-types";

/**
 * It is possible to get data from Firestore with a one off hit or listen for realtime updates.
 * This file provides methods for creating queries that listen for realtime updates.
 */


interface AssessmentUpdateHandler {
    (assessmentData: AssessmentInterface): void
}

export function getAssessmentDetail(assessment: Assessment, callbackFunc: AssessmentUpdateHandler) {
    const db = getFirestoreDb()

    return onSnapshot(doc(db, COLLECTION_ASSESSMENTS, assessment.id), (doc) => {
        const struct = doc.data() as AssessmentInterface
        callbackFunc(struct)
    });
}

export function subscribeToAssessmentUpdates(course: Course, callbackFunc: AssessmentUpdateHandler) {
    const db = getFirestoreDb()

    const id = course.courseId

    const col = collectionGroup(db, COLLECTION_STUDENTS);
    const queryRef = query(col)

    getDocs(queryRef).then((data) => {
        data.docs.forEach((doc) => {
            console.log(doc.id, ' => ', doc.data());
        })
    })
}

interface CallBackFunctionInterface {
    (): boolean
}
export function registerForUpdate(callbackFunc: CallBackFunctionInterface) {
    const db = getFirestoreDb()

    const id = 'course1'

    const col = collection(db, COLLECTION_ASSESSMENTS);
    const queryRef = query(col, where('classroom_id', '==', id))


    const unsub = onSnapshot(doc(db, COLLECTION_ASSESSMENTS, "b1647602280745"), (doc) => {

        const val = doc.data()
        if (val != undefined) {
            val.docs.forEach((element: any) => {
                console.log(element)
            });
        }
        console.log(" data: ", doc.data());

    });
}

export function registerForUpdateQuery(callbackFunc: CallBackFunctionInterface) {
    const db = getFirestoreDb()

    const id = 'course1'

    const col = collection(db, COLLECTION_ASSESSMENTS);
    const queryRef = query(col, where('classroom_id', '==', id))


    const unsub = onSnapshot(queryRef, (data) => {
        // console.log("snap")

        data.docs.forEach((doc) => {
            console.log("Student: " + doc.id, ' => ', doc.data());
        })

    });
}

interface AssessmentListHandler {
    (assessmentList: Assessment[]): void
}

export function subscribeToCourseAssessmentList(courseId: string, callbackFunc: AssessmentListHandler): Unsubscribe {
    const db = getFirestoreDb()

    const col = collection(db, COLLECTION_ASSESSMENTS);
    const queryRef = query(col, where('courseId', '==', courseId))

    const unsubscribe = onSnapshot(queryRef, (data) => {
        // const list: Assessment[] = []
        const list = data.docs.map((doc) => {
            const struct = extractStructFromFireStoreDoc(doc.data(), createAssessmentStruct());
            const assessment = new Assessment()
            assessment.fillFromAssessmentInterface(struct)
            return assessment
        })
        callbackFunc(list)
    });
    return unsubscribe;
}


interface CourseUpdateHandler {
    (course: Course): void
}

/**
 * Subscribe to receive notification when course details change
 * 
 */
export function subscribeToCourseUpdate(courseId: string, callbackFunc: CourseUpdateHandler): Unsubscribe {
    const db = getFirestoreDb()

    const col = collection(db, COLLECTION_COURSES);
    const queryRef = query(col, where('courseId', '==', courseId))

    const unsubscribe = onSnapshot(queryRef, (data) => {
        let course: Course = new Course()

        if (data.docs.length > 0) {
            const struct = extractStructFromFireStoreDoc(data.docs[0].data(), createCourseStruct());
            course = createCourse(struct)
        }
        callbackFunc(course)
    })

    return unsubscribe;
}

