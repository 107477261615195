import randomHashGenerator from "../helpers/randomHashGenerator";
import { UpdateAnalyticsInstanceType } from "./models/robot/update-info/UpdateInstance";

const PROJECT_ID = "marty-bt-robot-analytics-default-rtdb";
const REGION = "europe-west1";
const SESSION = "session-";
const UPDATES = "updates";
const WARNINGS = "warnings";

export const DB_URL = `https://${PROJECT_ID}.${REGION}.firebasedatabase.app/`;

export const sessionTitleUrlBuilder = (sessionId: string) => {
  return SESSION + sessionId;
};

export const updateInstanceUrlBuilder = (sessionId: string, updateType: UpdateAnalyticsInstanceType) => {
  const updateId = randomHashGenerator(10);
  return SESSION + sessionId + "/" + UPDATES + "/" + updateType + "-" + updateId;
};

export const warningInstanceUrlBuilder = (sessionId: string, warningTitle: string) => {
  const warningId = randomHashGenerator(10);
  return SESSION + sessionId + "/" + WARNINGS + "/" + warningTitle.replace(" ", "") + "-" +  warningId;
};
