import { useEffect, useRef } from "react";
import { ReactComponent as BatterySVG } from "../../assets/connect-button/battery.svg";
import {
  SCREENFREE_GREEN,
  SCREENFREE_RED,
  SCREENFREE_YELLOW,
} from "../../styles/colors";
import "./styles.css";

type MartyBatteryProps = {
  batteryStrength: number;
};

export default function MartyBattery({ batteryStrength }: MartyBatteryProps) {
  const batteryRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (batteryRef.current) {
      const svgStartPath = 3;
      const batteryBars = Math.ceil(batteryStrength / 20);
      let colour = SCREENFREE_GREEN;
      if (batteryStrength <= 10) {
        colour = SCREENFREE_RED;
      } else if (batteryStrength > 10 && batteryStrength <= 30) {
        colour = SCREENFREE_YELLOW;
      }
      for (let i = 0; i < 5; i++) {
        if (i < batteryBars) {
          batteryRef.current.children[i + svgStartPath].setAttribute(
            "fill",
            colour
          );
        } else {
          batteryRef.current.children[i + svgStartPath].setAttribute(
            "fill",
            "white"
          );
        }
      }
    }
  }, [batteryStrength]);

  return (
    <div className="marty-batt-container">
      <BatterySVG ref={batteryRef} /> <p className="marty-batt-percentage">{batteryStrength}%</p>
    </div>
  );
}
