export type AnalyticsConsentCookieType = "positive" | "negative";
const ANALYTICS_CONSENT = "@analytics_consent";

export function getStoredAnalyticsConsent(): AnalyticsConsentCookieType | null {
  return localStorage.getItem(ANALYTICS_CONSENT) as AnalyticsConsentCookieType;
}

export function setStoredAnalyticsConsent(analyticsConsent: AnalyticsConsentCookieType) {
  return localStorage.setItem(ANALYTICS_CONSENT, analyticsConsent);
}
