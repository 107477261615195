
import { Unsubscribe } from "@firebase/firestore"
import { useEffect, useState } from "react"
import { Course, createCourseStruct, CourseInterface, retrieveCourse, createCourse } from "../../../AutomatedAssessment/firestore/Course"
import { usingFirebase } from "../../../AutomatedAssessment/firestore/Firestore"
import { subscribeToCourseUpdate } from "../../../AutomatedAssessment/firestore/FirestoreSubscribe"
import { createAssessmentLogin } from "../../../AutomatedAssessment/user/AssessmentUser"
import { useStudent } from "../../../store/student-context"

import imgEmpty from "../../../assets/enjoyment/star-empty.svg";
import imgFull from "../../../assets/enjoyment/star-full.svg";
import imgBusy from "../../../assets/busy-32.gif";

import {
    EnjoymentQueryContainer, EnjoyModal, EnjoyModalHeader, EnjoyModalBody, EnjoyRow, EnjoyButton, EnjoyTitle,
    EnjoyError, EnjoyImg, EnjoySubmitButton, EnjoyModalFooter
} from "./styles";
import { CourseEnjoyment, saveCourseRating } from "../../../AutomatedAssessment/firestore/CourseEnjoyment"
import { getCourseRatingForStudent } from "../../../AutomatedAssessment/firestore/FirestoreQuery"

interface EnjoymentQueryProps {
    children?: JSX.Element
}

/**
 * Shows a modal requesting Enjoyment feedback
 * Does nothing unless a course is selected in studentContext. 
 * Is triggered via a call back from the Firestore database.
 */
const EnjoymentQuery = ({ children }: EnjoymentQueryProps) => {
    const { state: userState } = useStudent()
    const [isVisible, setIsVisible] = useState(false)
    const [busy, setBusy] = useState(false)
    const [rating, setRating] = useState(0)
    const [errorMsg, setErrorMsg] = useState("")
    const [enjoymentRequestId, setEnjoymentRequestId] = useState("")

    // Monitor for changes to the user content course
    useEffect(() => {
        let unsubscribe: Unsubscribe;

        const courseId = userState.currentCourse?.courseId
        if (courseId !== undefined) {
            // Subscribe to updates to this course - show enjoyment query if request has been issued.
            usingFirebase().then(() => {
                unsubscribe = subscribeToCourseUpdate(courseId, (course: Course) => {

                    // Do nothiing if an enjoyment request hasn't bee issued.
                    if (!course.enjoymentRequestIssued) {
                        return
                    }

                    // Check that the student hasn't already rated course/this-request
                    getCourseRatingForStudent(courseId, userState.id).then((ratingList: CourseEnjoyment[]) => {
                        // And filter for current engagement request
                        const finalRatingList = ratingList.filter((rating) => rating.enjoymentRequestId === course.enjoymentRequestId)
                        if (finalRatingList.length == 0) {
                            setIsVisible(true)
                            setRating(0)
                            setEnjoymentRequestId(course.enjoymentRequestId)
                            setBusy(false)
                        }
                    })
                })
            });
        }

        return () => {
            // Make sure we unsubscribe when we switch courses
            if (unsubscribe !== undefined) {
                unsubscribe();
            }
        };
    }, [userState.currentCourse?.courseId])


    const course: CourseInterface = (userState.currentCourse === undefined) ? new Course() : userState.currentCourse
    const title = "Course " + course.name

    const imageAbutton = (buttonRating: number) => {
        if (rating >= buttonRating) {
            return imgFull;
        }
        return imgEmpty;
    }

    const ratingStr = () => {
        if (rating === 0) {
            return " "
        }
        return "Rating " + ((rating === 1) ? "1 star" : rating + " stars")
    }

    const handleRatingSubmit = () => {
        if (rating === 0) {
            setErrorMsg("Please select a rating for this class.")
            return;
        }
        const courseId = userState.currentCourse?.courseId
        if (courseId === undefined) {
            setErrorMsg("A course is not currently selected.")
            return;
        }
        setBusy(true)
        setErrorMsg("")

        usingFirebase().then(() => {
            const courseRating = new CourseEnjoyment();
            courseRating.studentId = userState.id
            courseRating.courseId = courseId
            courseRating.enjoymentRequestId = enjoymentRequestId
            courseRating.rating = rating
            saveCourseRating(courseRating).then(() => {
                setIsVisible(false)
            })
        });

    }

    if (!isVisible) {
        return <></>
    }

    return (
        <EnjoymentQueryContainer>
            <EnjoyModal>

                <EnjoyModalBody>
                    <EnjoyTitle>{title}</EnjoyTitle>

                    {errorMsg && <EnjoyError>{errorMsg}</EnjoyError>}

                    <EnjoyRow>
                        How much have you enjoyed this lesson?
                        <br />Click the stars to rate this less and then submit.
                    </EnjoyRow>

                    <EnjoyRow>
                        <EnjoyButton onClick={() => setRating(1)} >
                            <EnjoyImg src={imageAbutton(1)} />
                        </EnjoyButton>
                        <EnjoyButton onClick={() => setRating(2)} >
                            <EnjoyImg src={imageAbutton(2)} />
                        </EnjoyButton>
                        <EnjoyButton onClick={() => setRating(3)} >
                            <EnjoyImg src={imageAbutton(3)} />
                        </EnjoyButton>
                        <EnjoyButton onClick={() => setRating(4)} >
                            <EnjoyImg src={imageAbutton(4)} />
                        </EnjoyButton>
                        <EnjoyButton onClick={() => setRating(5)} >
                            <EnjoyImg src={imageAbutton(5)} />
                        </EnjoyButton>
                    </EnjoyRow>

                    <EnjoyRow>&nbsp;{ratingStr()}</EnjoyRow>

                </EnjoyModalBody>

                <EnjoyModalFooter>
                    {!busy &&
                        <EnjoySubmitButton onClick={handleRatingSubmit}>Submit Rating</EnjoySubmitButton>
                    }
                    {busy && <img src={imgBusy} alt="Form Busy" />}
                </EnjoyModalFooter>

            </EnjoyModal>
        </EnjoymentQueryContainer>
    )
}

export default EnjoymentQuery