import React from "react";
import "./progress-bar.scss";

const ProgressBar = (props) => {
  const { completed, maximum } = props;
  const backgroundColours = ["white", "red", "orange", "green"];

  let max = 3;
  if (maximum != undefined) {
    max = maximum;
  }

  const toVal = maximum == undefined ? 3 : maximum;

  // const progressStr = () => {
  //   return "ok"
  //   // return completed + "/3"
  // }

  const progressStr = completed + (max == 3 ? "/" + max : "");

  return (
    <div className="progress-bar-container">
      <div
        className="filler"
        style={{
          width: (completed / max) * 100 + "%",
          backgroundColor: backgroundColours[completed],
        }}
      >
        <span className="label">{progressStr}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
