import "./styles.css";

import FirebaseManager from "../../../utils/analytics/models/FirebaseManager";
import { setStoredAnalyticsConsent } from "../../../utils/analytics/cookies/analytics-consent";

import { PALE_BLUE, BLACK_075 } from "../../../styles/colors";
import SimpleButton from "../../buttons/SimpleButton";
import modalState from "../../../state-observables/modal/ModalState";
import openNewTab from "../../../utils/open-new-tab";

const AnalyticsConsentModal = () => {
  const onConsent = (consentDecision: "positive" | "negative") => {
    // store answer
    setStoredAnalyticsConsent(consentDecision);
    // toggle analytics accordingly
    FirebaseManager.setAnalyticsConsent(consentDecision);
    // close modal
    modalState.closeModal();
  };

  return (
    <div className="analytics-consent-modal-container">
      <div className="analytics-consent-modal-info-container">
        <p className="analytics-consent-modal-info-paragraph">
          To help us make Marty better, we’d like to gather some info about your
          Marty and what features of the app you use.
        </p>
        <p className="analytics-consent-modal-info-paragraph">
          All data is strictly anonymous, confidential, and will never be shared
          outside of Robotical.
        </p>
        <p className="analytics-consent-modal-info-paragraph">
          Any questions, let us know at hello@robotical.io.
        </p>
      </div>
      <div className="analytics-consent-modal-buttons-container">
        <div className="analytics-consent-modal-buttons-yes-button">
          <SimpleButton
            title="YES, I ACCECT!"
            onClick={() => onConsent("positive")}
            titleSize="2.5rem"
          />
        </div>
        <div className="analytics-consent-modal-buttons-no-button">
          <SimpleButton
            title="NO, I DON'T ACCEPT"
            onClick={() => onConsent("negative")}
            titleSize="1.5rem"
          />
        </div>
        <div className="analytics-consent-modal-buttons-info-button">
          <SimpleButton
            title="MORE INFORMATION"
            onClick={() => openNewTab("https://robotical.io/privacy-policy/")}
            titleSize="1.5rem"
            textColour={BLACK_075}
            colour={PALE_BLUE}
          />
        </div>
      </div>
    </div>
  );
};

export default AnalyticsConsentModal;
