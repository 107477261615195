import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import MartyConnectionContext from "../../store/marty-connection-context";
import connectToMarty from "../../utils/network-api/connect-to-marty-function";
import NavButton from "../NavButton";
import { ReactComponent as MartyBlocksSVG } from "../../assets/nav-buttons/marty-blocks.svg";
import { ReactComponent as ControllerSVG } from "../../assets/nav-buttons/controller.svg";

import "./styles.css";
import NavLoginButton from "../NavLoginButton";

export default function NavButtons() {
  const navigate = useNavigate();
  const connectionCtx = useContext(MartyConnectionContext);
  const setConnectionLoading = connectionCtx.setIsConnectionLoading;

  const onRCSequencerClick = async () => {
    if (connectionCtx.isConnected) {
      navigate("/rcsequencer");
    } else {
      setConnectionLoading(true);
      try {
        await connectToMarty();
        setConnectionLoading(false);
        navigate("/rcsequencer");
      } catch (e) {
        setConnectionLoading(false);
      }
    }
  };

  return (
    <div className="nav-buttons-container">
      <div className="nav-buttons-row-container">
        <NavButton
          title="Marty Blocks"
          ImageComponent={MartyBlocksSVG}
          onClick={() => navigate("/marty-blocks")}
        />
        <NavButton
          title="Controller"
          ImageComponent={ControllerSVG}
          onClick={onRCSequencerClick}
        />
        <NavLoginButton />
      </div>
    </div>
  );
}
