import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getAssessmentCallBackInstance } from "../../AutomatedAssessment/automation/AssessmentHeartBeat";
import AssessmentScore from "../../components/AssessmentScore";
import ClassPause from "../../components/TeacherMessages/ClassPause";
import EnjoymentQuery from "../../components/TeacherMessages/EnjoymentQuery";
import NavLayout from "../../layout/NavLayout";
import MartyBlocksConnector, {
  MartyBlocksIF,
} from "../../MartyConnector/MartyBlocksConnector";
import martyConnector from "../../MartyConnector/MartyConnector";
import { useStudent } from "../../store/student-context";
import AppSession, { AnalyticsVisitedScreens } from "../../utils/analytics/models/app/AppSession";

const MainWindow = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  height: 95vh;
  width: 100%;
`;

const ContentWindow = styled.div`
  flex: auto;
  height: 100%;
`;

function MartyBlocks() {
  const [isMartyBlocksLoaded, setIsMartyBlocksLoaded] = useState(false);
  const { state: student } = useStudent()
  const navigate = useNavigate();
  const automatedAssessment = getAssessmentCallBackInstance()

  const handleMartyBlocksLoad = (e: SyntheticEvent<HTMLDivElement>) => {
    setIsMartyBlocksLoaded(true);
  };

  useEffect(() => {
    // Analytics
    AppSession.getInstance().storeVisitedScreen("scratch" as AnalyticsVisitedScreens);
  }, [AppSession.getInstance().sessionId]);

  useEffect(() => {
    let mbConnector: MartyBlocksConnector;

    if (isMartyBlocksLoaded) {
      mbConnector = new MartyBlocksConnector();

      // Marty Blocks is not always on Window index 0.
      // Check which window is referencing Marty Blocks
      let mv2: any
      let vmRuntime: any
      for (let i = 0; i < window.length; i++) {
        try {
          // If not Marty Blocks, mv2 will not be available and will throw error.
          mv2 = (window[i as any] as any).mv2
          vmRuntime = (window[i as any] as any).vm.runtime
          break;
        } catch {
          console.log("MV2 not set for window: " + i);
        }
      }

      mbConnector.setApp(mv2 as MartyBlocksIF);
      martyConnector.addUpdaterRemover(
        mbConnector.clearUpdater.bind(mbConnector)
      );

      const courseId: string = student.currentCourse ? student.currentCourse.courseId : ""
      automatedAssessment.startAssessment(student.id, courseId, vmRuntime)
    }
    return () => {
      automatedAssessment.stopAssessment()

      mbConnector && mbConnector.clearUpdater();
    };
  }, [isMartyBlocksLoaded]);

  return (
    <NavLayout onClickBack={() => navigate("/")}>
      <AssessmentScore assessmentScores={automatedAssessment} />
      <ClassPause />
      <EnjoymentQuery />
      <MainWindow>
        <ContentWindow>
          <object
            type="text/html"
            data="./MartyBlocks/index.html"
            onLoad={handleMartyBlocksLoad}
            style={{ width: "100%", height: "100%" }}
          ></object>
          {/* <object type="text/html" data="http://localhost:8601/index.html" onLoad={handleMartyBlocksLoad} style={{width:'100%', height:'100%'}}>
        </object> */}
        </ContentWindow>
      </MainWindow>
    </NavLayout>
  );
}

export default MartyBlocks;
