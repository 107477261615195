import { RICWifiScanResults, WifiScanWifiItem } from "@robotical/ricjs";
import { useEffect, useState } from "react";
import martyConnector from "../../MartyConnector/MartyConnector";
import SimpleButton from "../buttons/SimpleButton";
import LoadingSpinner from "../LoadingSpinner";
import "./styles.css";
import WifiItem from "./WifiItem";

interface WifiListProps {
  onItemPress: (item: WifiScanWifiItem) => void;
  selectedWifiBssid: string | undefined;
}

const WifiList = ({ onItemPress, selectedWifiBssid }: WifiListProps) => {
  const [wifiList, setWifiList] = useState<WifiScanWifiItem[]>([]);
  const [isScanning, setIsScanning] = useState(false);

  const wifiScan = async () => {
    setIsScanning(true);
    martyConnector
      .wifiScanResults()
      .then((wifiResults) => {
        if (wifiResults === false) {
          setIsScanning(false);
        } else {
          const wifiArr = wifiResults.wifi;
          setWifiList(wifiArr.filter((wifiElem) => wifiElem.ssid));
          setIsScanning(false);
        }
      })
      .catch((err) => setIsScanning(false))
      .finally(() => setIsScanning(false));
  };

  useEffect(() => {
    wifiScan();
  }, []);

  const wifiListJSX = wifiList.map((wifiItem) => {
    return (
      <WifiItem
        key={wifiItem.bssid}
        name={wifiItem.ssid}
        freq={4}
        bssid={wifiItem.bssid}
        onPress={() => onItemPress(wifiItem)}
        isSelected={selectedWifiBssid === wifiItem.bssid}
      />
    );
  });

  return (
    <div className="wifi-list-container">
      <div className="wifi-list-scan-button-container">
        <SimpleButton
          title="Scan Again"
          disabled={isScanning}
          onClick={wifiScan}
          otherStyles={{ paddingLeft: "2rem", paddingRight: "2rem" }}
        />
      </div>
      <div style={{ justifySelf:"center", marginBottom: "3rem", visibility: isScanning ? "initial" : "hidden" }}>
        <p className="wifi-list-loading-msg">Scanning...</p>
        <div className="wifi-list-spinner-container">
          <LoadingSpinner />
        </div>
      </div>
      {wifiListJSX}
    </div>
  );
};

export default WifiList;
