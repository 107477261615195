import semverGt from "semver/functions/gt";

export default function isVersionGreater(v1: string, v2: string) {
  try {
    return semverGt(v1, v2);
  } catch (e) {
    console.log(e, "compare-version.ts", "line: ", "8");
    return false;
  }
}
