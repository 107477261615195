import FirebaseManager from "../../utils/analytics/models/FirebaseManager";
import "./styles.css";

export default function HelpScreenText() {
  const consent = FirebaseManager.getAnalyticsConsent();

  let optingOutAnalyticsOptions =
    "You can opt out at anytime by pressing the Data Analytics button at the bottom of the page.";
  return (
    <>
      <h2 className="help-screen-text-title">HELP {"&"} SUPPORT</h2>

      <p className="help-screen-text-paragraph">
        <span className="help-screen-text-bold-span">Analytics:</span>
      </p>
      <p className="help-screen-text-paragraph" style={{marginBottom: "2rem"}}>
        {`
            You are currently`}{" "}
        {consent === "negative" ? (
          <span className="help-screen-text-bold-span">not</span>
        ) : (
          ""
        )}{" "}
        {`consenting to data analytics \n`}
        {consent === "positive" ? (
          <span className="help-screen-text-paragraph">{`You will need a connection to the internet for this to work.\n${optingOutAnalyticsOptions}\n`}</span>
        ) : null}
      </p>

      <p className="help-screen-text-paragraph">
        <span className="help-screen-text-bold-span">Troubleshooting:</span>
      </p>
      <p className="help-screen-text-paragraph">
        Please make sure that your device's bluetooth is turned on.
      </p>
      <p className="help-screen-text-paragraph">
        Make sure your Marty is on with Bluetooth enabled and you are near your
        Marty.
      </p>
      <p className="help-screen-bottom-paragraph">
        If you are connected to other bluetooth devices please disconnect and
        then retry connecting to Marty.
      </p>
      <p className="help-screen-bottom-paragraph">
        For further support, press the FAQ button below:
      </p>
    </>
  );
}
