import styled from "styled-components";

export const AssessmentWindow = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
`
export const AssessmentModal = styled.div`
    background: var(--colour-GREY_2);
    border-radius: 15px;
    padding: 15px;
    width: 90%;
`
export const AssessmentModalHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    font-size: 25px;
`
export const AssessmentModalBody = styled.div`
    padding-top: 10px;

    >div {
        margin: 6px 0;
`

export const AssessmentTitle = styled.div`
    font-size: 2.5rem;
    font-family: "BigNoodleTitling Regular";
    text-align: center;
`

export const AssessmentRow = styled.div`
    display: flex;
    justify-content: center;
`

export const AssessmentButton = styled.div`
    cursor: pointer;
    padding: 6px;
`

export const AssessmentModalFooter = styled.div`
    display: flex;
    justify-content: right;
    min-height: 38px;
`

export const AssessmentError = styled.div`
    background: var(--colour-RED);
    padding: 12px;
    font-size: 1.1em;
    color: White;
`

export const AssessmentCloseButton = styled.button`
    background: var(--colour-MAIN_BLUE);
    border-radius: 6px;
    font-size: 1.1em;
    padding: 6px;
    min-height: 32px;
    width: 150px;
`
export const NavButton = styled.div`
  margin-left: 70%;
`
