import { doc, setDoc, } from "@firebase/firestore";
import { Course } from "./Course";
import { getFirestoreDb } from "./Firestore";
import { Student } from "./Student";
import { AssessmentScoreInterface, BadHabitScoreInterface } from "..";
import dayjs from "dayjs";

export const COLLECTION_ASSESSMENTS = "Assessments"

export interface AssessmentInterface extends AssessmentScoreInterface, BadHabitScoreInterface {
    courseId: string,
    studentId: string,
    updatedAt: Date,
}

export function createAssessmentStruct(): AssessmentInterface {
    // Simple way to create an object implementing interface...
    // i. Create class which initialises all required properties
    // ii. Use copy with empty object so just interace properties are copied to it.
    const assessment = new Assessment()
    return copyAssessment(assessment, {})
}

export function createAssessment(assessmentInterfaceItem: AssessmentInterface): Assessment {
    const assessment = new Assessment()
    copyAssessment(assessmentInterfaceItem, assessment)
    return assessment;
}

export function copyAssessment(fromAssessment: AssessmentInterface, toAssessment: any): AssessmentInterface {
    toAssessment.courseId = fromAssessment.courseId
    toAssessment.studentId = fromAssessment.studentId
    toAssessment.updatedAt = fromAssessment.updatedAt
    copyAssessmentScore(fromAssessment, toAssessment)
    copyBadHabitScore(fromAssessment, toAssessment)

    return toAssessment as AssessmentInterface
}

function copyAssessmentScore(fromScore: AssessmentScoreInterface, toAssessment: any): AssessmentScoreInterface {
    toAssessment.abstraction = fromScore.abstraction
    toAssessment.dataRepresentation = fromScore.dataRepresentation
    toAssessment.flowControl = fromScore.flowControl
    toAssessment.interactivity = fromScore.interactivity
    toAssessment.logic = fromScore.logic
    toAssessment.parallelism = fromScore.parallelism
    toAssessment.synchronisation = fromScore.synchronisation

    return toAssessment as AssessmentScoreInterface
}


function copyBadHabitScore(fromScore: BadHabitScoreInterface, toScore: any): BadHabitScoreInterface {
    toScore.badNaming = fromScore.badNaming
    toScore.deadCode = fromScore.deadCode
    toScore.duplication = fromScore.duplication

    return toScore as BadHabitScoreInterface
}

export class Assessment implements AssessmentInterface, BadHabitScoreInterface {
    public abstraction: number = 0
    public dataRepresentation: number = 0
    public flowControl: number = 0
    public interactivity: number = 0
    public logic: number = 0
    public parallelism: number = 0
    public synchronisation: number = 0
    public updatedAt: Date
    public studentName: string = ""
    public courseName: string = ""
    public deadCode: number = 0
    public duplication: number = 0
    public badNaming: number = 0

    public student?: Student
    public course?: Course

    private _courseId: string = ''
    private _studentId: string = ''

    constructor() {
        this.updatedAt = new Date()
    }


    fillFromAssessmentInterface(data: AssessmentInterface) {
        copyAssessment(data, this);
    }

    get id(): string {
        return this.courseId + "-" + this.studentId
    }

    get studentId(): string {
        return this.student?.studentId ?? this._studentId
    }

    get courseId(): string {
        return this.course?.courseId ?? this._courseId;
    }

    /**
     * Indicates if the studend assocated with this assessment has been active
     * in course within the last hour
     */
    get isActive(): boolean {
        const start = dayjs(this.updatedAt);
        const diff = dayjs().diff(start, "minutes");
        return (diff < 60)
    }

    set studentId(val: string) {
        if (this.student != undefined) {
            if (val != this.student.studentId) {
                this.student = undefined
            }
        }
        this._studentId = (this.student == undefined) ? val : "";
    }

    set courseId(val: string) {
        if (this.course != undefined) {
            if (val != this.course.courseId) {
                this.course = undefined
            }
        }
        this._courseId = (this.course == undefined) ? val : "";
    }

    setScore(score: AssessmentScoreInterface) {
        copyAssessmentScore(score, this)
    }
    setBadHabitScore(score: BadHabitScoreInterface) {
        copyBadHabitScore(score, this)
    }
}


export function saveAssessment(assessment: Assessment) {
    if (assessment.studentId == '') {
        throw ("Save Assessment - Student has not been set for assessment")
    }
    if (assessment.courseId == '') {
        throw ("Save Assessment - Course has not been set for assessment")
    }
    const key = assessment.id

    // Record save information
    assessment.updatedAt = new Date()

    // We only want to save limited set of fields.  Use copy to restrict.
    const assessmentData = copyAssessment(assessment, {})

    const db = getFirestoreDb();
    setDoc(doc(db, COLLECTION_ASSESSMENTS, key), assessmentData, { merge: true });
}

