import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Logo from "../Logo";
import {
  StyledModalOverlay,
  StyledModal,
  StyledModalBody,
  StyledModalHeader,
  StyledTitle,
} from "./styles";

type ModalPropsTypes = {
  children: React.ReactChild | null;
  title: string;
  shouldCloseModal: boolean;
  withLogo?: boolean;
  modalResetter: () => void;
};

function Modal({
  children,
  title,
  shouldCloseModal,
  withLogo,
  modalResetter
}: ModalPropsTypes) {
  const [isBrowser, setIsBrowser] = useState(false);
  const [closing, setClosing] = useState(false);
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    setIsBrowser(true);
    if (showModal) {
      window.scrollTo({ top: 0 });
      document
        .getElementsByTagName("body")[0]
        .setAttribute("style", "overflow: hidden");
    }
  }, [showModal]);

  useEffect(() => {
    if (shouldCloseModal) {
      closeModalHandler();
    }
  }, [shouldCloseModal]);

  const closeModalHandler = () => {
    setClosing(true);
    const timer = setTimeout(() => {
      document
        .getElementsByTagName("body")[0]
        .setAttribute("style", "overflow: auto");
        setShowModal(false);
        modalResetter();
      clearTimeout(timer);
    }, 700);
  };

  const modalContent = showModal ? (
    <StyledModalOverlay>
      <StyledModal $closing={closing}>
        <StyledModalHeader></StyledModalHeader>
        {withLogo && <div className="modal-logo-container"><Logo /></div>}
        {title && <StyledTitle>{title}</StyledTitle>}
        <StyledModalBody>{children}</StyledModalBody>
      </StyledModal>
    </StyledModalOverlay>
  ) : null;

  if (isBrowser && showModal) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById("modal-root")!
    );
  } else {
    return null;
  }
}
export default Modal;
