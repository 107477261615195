import { Target } from "../types/main";
import { AssessmentScoreInterface, assess, badHabits, BadHabitScoreInterface } from "../index";
import { Assessment, saveAssessment } from "../firestore/Assessment";
import { usingFirebase } from "../firestore/Firestore";
import { saveAssessmentHistory } from "../firestore/AssessmentHistory";

const INTERVAL_IN_SECONDS = 13;

/**
 * Assessment CallBack Class Setsup the Time interval logging of assessment sources
 *
 * This is a singleton class accessed via getAssessmentCallBackInstance function.
 *
 */
let moduleInstance: AssessmentHeartBeat;

export function getAssessmentCallBackInstance(): AssessmentHeartBeat {
    if (moduleInstance === undefined) {
        moduleInstance = new AssessmentHeartBeat();
    }
    return moduleInstance;
}

class AssessmentHeartBeat {
    private timerHandle: NodeJS.Timeout | null = null;
    private started: boolean = false;
    private studentId: string = "";
    private courseId: string = "";
    private martyBlockVm?: ScratchVmRuntimeInterface;
    private lastScore?: AssessmentScoreInterface;

    public startAssessment(studentId: string, courseId: string, martyBlockVm: ScratchVmRuntimeInterface): boolean {
        this.stopAssessment();

        if (studentId === "" || courseId === "") {
            return false;
        }
        this.studentId = studentId;
        this.courseId = courseId;
        this.martyBlockVm = martyBlockVm;

        // Start the assessment
        const start = this.getTimeoutHandler();
        start();

        this.started = true;
        return true;
    }

    public stopAssessment() {
        if (this.started && this.timerHandle != null) {
            clearTimeout(this.timerHandle);
            this.started = false;
        }
    }

    public getBadHabitsScore(): BadHabitScoreInterface {
        const score: BadHabitScoreInterface = {
            badNaming: 0,
            deadCode: 0,
            duplication: 0,
        }

        if (!this.started || this.martyBlockVm === undefined) {
            console.log("Assessments not started");
            return score;
        }
        const vm: ScratchVmRuntimeInterface = this.martyBlockVm;
        const result = badHabits(vm.targets);

        score.badNaming = result["BadNaming"]
        score.deadCode = result["DeadCode"]
        score.duplication = result["Duplication"]

        return score
    }

    public getCurrentScore(): AssessmentScoreInterface {
        const score: AssessmentScoreInterface = {
            abstraction: 0,
            dataRepresentation: 0,
            flowControl: 0,
            interactivity: 0,
            logic: 0,
            parallelism: 0,
            synchronisation: 0,
        };

        if (!this.started || this.martyBlockVm === undefined) {
            return score;
        }
        const vm: ScratchVmRuntimeInterface = this.martyBlockVm;

        const result = assess(vm.targets);

        score.dataRepresentation = result["DataRepresentation"];
        score.flowControl = result["FlowControl"];
        score.interactivity = result["Interactivity"];
        score.logic = result["Logic"];
        score.parallelism = result["Parallelism"];
        score.synchronisation = result["Synchronisation"];

        return score;
    }

    public getTotalScore(): number {
        let total = 0

        const score = this.getCurrentScore()
        total += score.dataRepresentation
        total += score.flowControl
        total += score.interactivity
        total += score.logic
        total += score.parallelism
        total += score.synchronisation

        return total
    }

    private getTimeoutHandler() {
        const self = this;

        return function () {
            const score = self.getCurrentScore();
            const badHabitScore = self.getBadHabitsScore();

            // Only send if score has been updated.
            if (self.lastScore === undefined || !self.scoresEqual(score, self.lastScore)) {
                self.lastScore = score;

                // Save the assessment information to firestore
                const assessment = new Assessment();
                assessment.studentId = self.studentId;
                assessment.courseId = self.courseId;
                assessment.setScore(score);
                assessment.setBadHabitScore(badHabitScore)

                usingFirebase().then(() => {
                    saveAssessment(assessment)
                    saveAssessmentHistory(assessment)
                });
            }

            self.timerHandle = setTimeout(self.getTimeoutHandler(), INTERVAL_IN_SECONDS * 1000);
        };
    }

    private scoresEqual(score1: AssessmentScoreInterface, score2: AssessmentScoreInterface) {
        let same = true;
        same &&= score1.abstraction === score2.abstraction;
        same &&= score1.dataRepresentation === score2.dataRepresentation;
        same &&= score1.flowControl === score2.flowControl;
        same &&= score1.interactivity === score2.interactivity;
        same &&= score1.logic === score2.logic;
        same &&= score1.parallelism === score2.parallelism;
        same &&= score1.synchronisation === score2.synchronisation;
        return same;
    }
}

interface ScratchVmRuntimeInterface {
    targets: Target[];
}
