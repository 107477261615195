import { Unsubscribe } from "@firebase/firestore"
import { useEffect, useState } from "react"
import { Course, createCourseStruct, CourseInterface, retrieveCourse, createCourse } from "../../AutomatedAssessment/firestore/Course"
import { usingFirebase } from "../../AutomatedAssessment/firestore/Firestore"
import { subscribeToCourseUpdate } from "../../AutomatedAssessment/firestore/FirestoreSubscribe"
import { createAssessmentLogin } from "../../AutomatedAssessment/user/AssessmentUser"
import { useStudent } from "../../store/student-context"
import { ReactComponent as GraphSVG } from "../../assets/graph.svg";


import {
    AssessmentWindow, AssessmentModal, AssessmentModalHeader, AssessmentModalBody, AssessmentRow, AssessmentButton, AssessmentTitle,
    AssessmentCloseButton, AssessmentModalFooter, NavButton
} from "./styles";

import AssessmentContainer from "./AssessmentContainer/assessment-container"
import { AssessmentScoreInterface, BadHabitScoreInterface } from "../../AutomatedAssessment"

interface AssessmentComponentPropInterface {
    getBadHabitsScore(): BadHabitScoreInterface,
    getCurrentScore(): AssessmentScoreInterface,
    getTotalScore(): number
}
interface AssessmentProps {
    children?: JSX.Element
    assessmentScores: AssessmentComponentPropInterface
}

/**
 * Shows a modal requesting Assessmentment feedback
 */
const AssessmentScore = ({ children, assessmentScores }: AssessmentProps) => {
    const [isVisible, setIsVisible] = useState(false)

    const handleRatingSubmit = () => { setIsVisible(false) }
    const showAssessmentWindow = () => { setIsVisible(true) }

    return (
        <>
            <NavButton className="nav-layout-go-back-btn" onClick={showAssessmentWindow}>
                <GraphSVG />
            </NavButton>

            {isVisible && <AssessmentWindow>
                <AssessmentModal>

                    <AssessmentModalBody>
                        <AssessmentTitle>Code Assessment</AssessmentTitle>

                        <AssessmentContainer assessmentScores={assessmentScores} />

                    </AssessmentModalBody>

                    <AssessmentModalFooter>
                        <AssessmentCloseButton onClick={handleRatingSubmit}>Close</AssessmentCloseButton>
                    </AssessmentModalFooter>

                </AssessmentModal>
            </AssessmentWindow>
            }
        </>
    )
}

export default AssessmentScore