import { MartyConnectionContextTypes } from "../store/marty-connection-context";

export default class StoreEventEmitter {
  public connectionCtx: MartyConnectionContextTypes | null = null;
  constructor() {}

  setConnectionCtx(connectionCtx: MartyConnectionContextTypes) {
    this.connectionCtx = connectionCtx;
  }
}
