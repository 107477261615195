import { useEffect } from "react";
import SimpleButton from "../../components/buttons/SimpleButton";
import { CalibrationComponentType } from "../../navigation/CalibrationNavigator";
import AppSession, {
  AnalyticsVisitedScreens,
} from "../../utils/analytics/models/app/AppSession";
import {
  getCalibrationImage,
  getCalibrationStrings,
  Steps,
} from "../../utils/helpers/calibration-screen";
import "./styles.css";

interface CalibrationScreenProps extends CalibrationComponentType {}

export default function CalibrationScreen({
  currentScreenSetter,
}: CalibrationScreenProps) {
  useEffect(() => {
    // Analytics
    AppSession.getInstance().storeVisitedScreen("calibration" as AnalyticsVisitedScreens);
  }, [AppSession.getInstance().sessionId]);

  return (
    <div className="calibration-screen-container">
      <div className="calibration-screen-marty-image-container">
        <img src={getCalibrationImage(Steps.STEP01)} alt="marty-image" />
      </div>
      <div className="calibration-screen-middle-text-container">
        <p className="calibration-screen-middle-text-title">
          {getCalibrationStrings(Steps.STEP01).title}
        </p>
        <p className="calibration-screen-middle-text-subtitle">
          {getCalibrationStrings(Steps.STEP01).hint}
        </p>
      </div>
      <div className="calibration-screen-bottom-buttons-container">
        <SimpleButton
          title="EYE CALIBRATION"
          onClick={() => currentScreenSetter("eye-calibration")}
        />
        <SimpleButton
          title="ARM CALIBRATION"
          onClick={() => currentScreenSetter("arm-calibration")}
        />
        <SimpleButton
          title="LEG CALIBRATION"
          onClick={() => currentScreenSetter("leg-calibration-1")}
        />
      </div>
    </div>
  );
}
