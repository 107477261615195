import { getGoogleAssessmentUser } from "../googleapi/GoogleAssessmentUser";
import { CourseInterface } from "../firestore/Course"

/**
 * Basic Assessment User details that is used at context level and 
 * shared between components.
 */
export interface AssessmentUserInterface {
    isLoggedIn: boolean,
    id: string,
    name: string,
    imageUrl: string,
    currentCourse?: CourseInterface,
}

export function createAssessmentUser(): AssessmentUserInterface {
    return {
        isLoggedIn: false,
        id: '',
        name: '',
        imageUrl: ''
    }
}

/**
 * Initially using Google/Google classroom to login user's in and 
 * get their course details. But this information could come from
 * any provider.
 * This is a generic user login interface allowing us to use any provider.
 */
export interface AssessmentUserLoginInterface {
    init(callbackFunc: UserUpdateCallBack): void;
    signIn(): void;
    signOut(): void;
    attendingCourseList(): Promise<CourseInterface[]>;
    runningCourseList(): Promise<CourseInterface[]>;
}

export interface UserUpdateCallBack {
    (status: AssessmentUserInterface): void,
}

/**
 * Factory to create the AssessmentUserLogin we require.
 * 
 */
export const createAssessmentLogin = (): AssessmentUserLoginInterface => {

    // Initially using Google Classroom
    return getGoogleAssessmentUser();
}

