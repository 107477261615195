/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
// RobotSession singleton class
// Responsible for managing robot
// sessions (collecting and storing info)
// Communicating with the analytics db
//
// (C) Robotical 2022
//
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import randomHashGenerator from "../../../helpers/randomHashGenerator";
import { RICHWElem } from "@robotical/ricjs/src/RICTypes";
import {
  sessionTitleUrlBuilder,
  updateInstanceUrlBuilder,
  warningInstanceUrlBuilder,
} from "../../robot-db-urls";
import FirebaseManager from "../FirebaseManager";
import UpdateInstance, {
  UpdateAnalyticsErrorType,
  UpdateAnalyticsInstanceType,
  UpdateTitleType,
} from "./update-info/UpdateInstance";
import WarningMessageInstance, {
  MessageObjType,
} from "./warning-info/WarningMessageInstance";

type DataType = {
  firstTimestamp: string;
  lastTimestamp: string;
  serialNo: string;
  systemVersion: string;
  sessionId: string;
  appSessionId: string;
};

export default class RobotSession {
  private static instance: RobotSession | null = null;
  private serialNumber!: string; // Marty's serial number
  public sessionId!: string;
  private heartbeat: NodeJS.Timeout | undefined;
  private systemVersion!: string;
  private heartbeatInterval: number = 60000;

  constructor() {}

  public static getInstance() {
    // this class is singleton: only one instance should exist across
    // the whole app. The getInstance method either returns the
    // instance of the class (if it exists) or creates one;
    if (this.instance) return this.instance;
    const newInstance = new RobotSession();
    this.instance = newInstance;
    return newInstance;
  }

  public setSerialNumber(sn: string) {
    this.serialNumber = sn;
  }

  public setSystemVersion(sv: string) {
    this.systemVersion = sv;
  }

  private setHeartbeat() {
    // every this.heartbeatInterval we sent a new timestamp
    // to the db. In that way we can estimate the time
    // marty was used.
    if (!this.heartbeat) {
      this.heartbeat = setInterval(() => {
        const fbPath = sessionTitleUrlBuilder(this.sessionId);
        FirebaseManager.EXISTS("ROBOT_DB_URL", fbPath)
          ?.then((res) => res.json())
          .then((r) => {
            if (r) {
              // send heartbeat only if session already exists
              FirebaseManager.PATCH(
                "ROBOT_DB_URL",
                "lastTimestamp",
                new Date().toISOString(),
                fbPath
              )?.catch((err) =>
                console.log(err, "RobotSession.ts", "line: ", "103")
              );
            }
          });
      }, this.heartbeatInterval);
    }
  }

  public stopHeartbeat() {
    // stopping repeatedly sending msgs to the db
    if (this.heartbeat) {
      clearInterval(this.heartbeat);
    }
    this.heartbeat = undefined;
  }

  public addUpdateInstance(
    instanceType: UpdateAnalyticsInstanceType,
    title: UpdateTitleType,
    msg: string,
    errorType: UpdateAnalyticsErrorType | undefined,
    fwUpdateCheckCount: number | undefined
  ) {
    // if sessionId is not defined then cancel
    if (!this.sessionId) return;
    // update instance can be either an update faliure or update success
    const instance = new UpdateInstance(
      instanceType,
      title,
      msg,
      errorType,
      fwUpdateCheckCount
    );
    const fbPath = updateInstanceUrlBuilder(
      this.sessionId,
      instance.instanceType
    );
    FirebaseManager.POST("ROBOT_DB_URL", instance, fbPath)?.catch((err) =>
      console.log(err, "RobotSession.ts", "line: ", "68")
    );
  }

  public addWarningMessage(
    messageObj: MessageObjType,
    motorElem: RICHWElem | undefined
  ) {
    // if sessionId is not defined then cancel
    if (!this.sessionId) return;
    // warning messages such as fall detection and current motor warning
    const warningMessage = new WarningMessageInstance(messageObj, motorElem);
    const fbPath = warningInstanceUrlBuilder(
      this.sessionId,
      warningMessage.title
    );
    FirebaseManager.POST("ROBOT_DB_URL", warningMessage, fbPath)?.catch((err) =>
      console.log(err, "RobotSession.ts", "line: ", "78")
    );
  }

  public startSession(
    serialNumber: string,
    systemVersion: string,
    appSessionId: string
  ) {
    this.setSerialNumber(serialNumber);
    this.setSystemVersion(systemVersion);
    // A marty was just successfully connected to the app. Starting session
    this.sessionId = randomHashGenerator(10);
    // safety net: making sure we only post when
    // the serial number is defined
    if (this.serialNumber) {
      const data: DataType = {
        firstTimestamp: new Date().toISOString(),
        lastTimestamp: new Date().toISOString(),
        serialNo: this.serialNumber,
        systemVersion: this.systemVersion,
        sessionId: this.sessionId,
        appSessionId: appSessionId,
      };
      // sending the initial data. when the initial data are sent
      // we set up a heartbeat
      FirebaseManager.POST(
        "ROBOT_DB_URL",
        data,
        sessionTitleUrlBuilder(this.sessionId)
      )
        ?.then((res: Response) => {
          if (res.ok) {
            this.setHeartbeat();
          }
        })
        .catch((err) => console.log(err, "RobotSession.ts", "line: ", "96"));
    }
  }
}
