import ProgressBar from "../progress-bar/progress-bar";

import "./bad-habit-card.scss"

interface BadHabitsCardPropInterface  {
  duplication: number,
  deadCode: number,
  badNaming: number
}

const BadHabitsCard = ({duplication, deadCode, badNaming} : BadHabitsCardPropInterface) => {

  let max = 10
  max = (max > duplication) ? max : (duplication + 10)
  max = (max > badNaming) ? max : (badNaming + 10)
  max = (max > deadCode) ? max : (deadCode + 10)

  return (
    <div className="bad-habits-card">
      <div className="row heading">
        <div className="col">Bad Habit</div>
        <div className="col">Frequency</div>
      </div>

      <div className="row">
        <div className="col">Duplication</div>
        <div className="col">
          <ProgressBar completed={duplication} maximum={max} />
        </div>
      </div>

      <div className="row">
        <div className="col">Dead Code</div>
        <div className="col">
          <ProgressBar completed={deadCode} maximum={max} />
        </div>
      </div>

      <div className="row">
        <div className="col">Bad Naming</div>
        <div className="col">
          <ProgressBar completed={badNaming} maximum={max} />
        </div>
      </div>
    </div>
  );
};

export default BadHabitsCard;
