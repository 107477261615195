import { doc, onSnapshot, Firestore, getFirestore, addDoc, collection, query, where } from "@firebase/firestore";
import { getFirestoreDb } from "./Firestore";
import { Assessment, copyAssessment } from "./Assessment";

export const COLLECTION_ASSESSMENT_HISTORY = "AssessmentHistory";

export function saveAssessmentHistory(assessment: Assessment) {
    if (assessment.studentId == "") {
        throw "Save Assessment History - Student has not been set for assessment";
    }
    if (assessment.courseId == "") {
        throw "Save Assessment History - Course has not been set for assessment";
    }
    // Record save information
    assessment.updatedAt = new Date();

    const assessmentData = copyAssessment(assessment, {});

    const db = getFirestoreDb();
    addDoc(collection(db, COLLECTION_ASSESSMENT_HISTORY), assessmentData);
}
