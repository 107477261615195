
import { Unsubscribe } from "@firebase/firestore"
import { useEffect, useState } from "react"
import { Course, createCourseStruct, CourseInterface, retrieveCourse, createCourse } from "../../../AutomatedAssessment/firestore/Course"
import { usingFirebase } from "../../../AutomatedAssessment/firestore/Firestore"
import { subscribeToCourseUpdate } from "../../../AutomatedAssessment/firestore/FirestoreSubscribe"
import { createAssessmentLogin } from "../../../AutomatedAssessment/user/AssessmentUser"
import { useStudent } from "../../../store/student-context"
import SimpleButton from "../../buttons/SimpleButton"
import { StyledModal, StyledModalBody, StyledModalHeader, StyledModalOverlay, StyledTitle } from "./styles"

interface ClassPauseProps {
    children?: JSX.Element
}

/**
 * Shows a modal informing user that the class has been paused.
 * Does nothing unless a course is selected in student-context. 
 * Is triggered via a call back from the Firestore database.
 */
const ClassPause = ({ children }: ClassPauseProps) => {
    const { state: userState } = useStudent()
    const [isVisible, setIsVisible] = useState(false)

    // Monitor for changes to the user content course
    useEffect(() => {
        let unsubscribe: Unsubscribe;

        const courseId = userState.currentCourse?.courseId
        if (courseId != undefined) {
            // Subscribe to updates to this course - show modal if the course is paused.
            usingFirebase().then(() => {
                unsubscribe = subscribeToCourseUpdate(courseId, (course: Course) => {
                    setIsVisible(course.isPaused)
                })
            });
        }

        return () => {
            // Make sure we unsubscribe when we switch courses
            if (unsubscribe != undefined) {
                unsubscribe();
            }
        };
    }, [userState.currentCourse?.courseId])


    const course: CourseInterface = (userState.currentCourse == undefined) ? new Course() : userState.currentCourse
    const title = "Course "  + course.name 

    if (!isVisible) {
        return <></>
    }

    return (
        <StyledModalOverlay>
            <StyledModal>
                <StyledModalHeader></StyledModalHeader>
                <StyledTitle>{title}</StyledTitle>
                <StyledModalBody>

                    <div>
                        This class has been paused by the teacher.
                    </div>

                </StyledModalBody>
            </StyledModal>
        </StyledModalOverlay>
    )
}

export default ClassPause