// Import the functions you need from the SDKs you need
import { Firestore, getFirestore, Timestamp } from "@firebase/firestore";
// import { initializeApp, FirebaseApp } from "firebase/app";
import { initializeApp } from "@firebase/app";
// import firebase from 'firebase/compat/app';
import { getAuth, GoogleAuthProvider, OAuthCredential, signInWithCredential } from "@firebase/auth";
import { DocumentData } from "@firebase/firestore-types";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD29_CwiXcC2Fg-JHhQKZUauFLnxPHcvoY",
    authDomain: "robotical-343409.firestoreApp.com",
    projectId: "robotical-343409",
    storageBucket: "robotical-343409.appspot.com",
    messagingSenderId: "552215870134",
    appId: "1:552215870134:web:fc8fe9700f76c247a00417",
    measurementId: "G-Z6KC4G10QN",
};

enum InitState {
    Ready,
    InProgress,
    Done,
}

// Firebase state variables.
let firebaseInitialisedFlag = InitState.Ready;
let firestoreDb: Firestore;

export async function usingFirebase() {
    // Check if already initialised
    if (firebaseInitialisedFlag === InitState.Done) {
        // console.log("usingFirebase - already initialised");
        return true;
    }

    // Check logged to a google account
    const auth2 = gapi.auth2.getAuthInstance();
    const userSignedIn = auth2.isSignedIn.get();
    if (userSignedIn) {
        return initFirebase(auth2);
    }
    throw "User not logged in with a Google account";
}

async function initFirebase(authInstance: gapi.auth2.GoogleAuth): Promise<boolean> {
    // Check if already initialised
    if (firebaseInitialisedFlag === InitState.Done) {
        return true;
    }

    firebaseInitialisedFlag = InitState.InProgress;

    if (!authInstance.isSignedIn) {
        firebaseInitialisedFlag = InitState.Ready;
        return false;
    }

    // Initialise and sign in
    const firestoreApp = initializeApp(firebaseConfig);

    const currentUser = authInstance.currentUser.get();
    const authResponse = currentUser.getAuthResponse(true);
    // const credential = firebase.auth.GoogleAuthProvider.credential(
    const credential = GoogleAuthProvider.credential(authResponse.id_token, authResponse.access_token);

    const signedIn = await signInWithCredential(getAuth(), credential);
    if (signedIn) {
        firebaseInitialisedFlag = InitState.Done;
        try {
            firestoreDb = await getFirestore(firestoreApp);
        } catch (exception) {
            console.log("exception");
            console.log(exception);
            return false;
        }

        return true;
    }

    firebaseInitialisedFlag = InitState.Ready;
    return false;
}

export function hasFirebaseBeenInitialised(): boolean {
    return firebaseInitialisedFlag == InitState.Done;
}

export function getFirestoreDb() {
    if (!hasFirebaseBeenInitialised()) {
        throw "Firebase has not been initialised";
    }
    return firestoreDb;
}

/**
 * Populates any object with data from a firestore document.
 * FireStore uses it's own time stamps - we need this extraction if we want to convert timestamps to JS dates.
 * It uses keys from the "to object" to determine information to extract.
 */
export function extractStructFromFireStoreDoc<T>(data: DocumentData, toObject: T): T {
    let k: keyof typeof toObject;
    for (k in toObject) {
        const key = (k as string)
        if (data[key] == undefined) {
            continue;
        }

        // Convert firestore time stamp to a standard javascript date.
        if (data[key] instanceof Timestamp) {
            toObject[k] = data[key].toDate()
        } else {
            toObject[k] = data[key]
        }
    }
    return toObject;
}

/**
 * When saving an object to the Firestore database we what to ensure we only save 
 * the "firestore-interface" fields.  The object passed for storing must implement
 * interface, but may contain additional fields.
 * This function fills a basic struct to store to database from passed object.
 */
export function getFirestoreStruct<T>(firebaseStruct: T, data: T): T {
    let k: keyof typeof firebaseStruct;
    for (k in firebaseStruct) {
        firebaseStruct[k] = data[k]
    }
    return firebaseStruct;
}

