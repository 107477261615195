import { useContext } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import ConfigurationNavigator from "../navigation/ConfigurationNavigator";
import HelpNavigator from "../navigation/HelpNavigator";
import HomeScreen from "../screens/Home";
import MartyBlocks from "../screens/MartyBlocks/MartyBlocks";
import RCSequencer from "../screens/RCSequencer/RCSequencer";
import MartyConnectionContext from "../store/marty-connection-context";
import DevelopScreen from "../screens/Develop/Develop";

export default function Router() {
  const connectionCtx = useContext(MartyConnectionContext);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route
          path="/configuration"
          element={
            connectionCtx.isConnected ? (
            <ConfigurationNavigator key={new Date().getTime()}/>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/rcsequencer"
          element={
            connectionCtx.isConnected ? (
              <RCSequencer />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route path="/develop" element={<DevelopScreen />} />
        <Route path="/marty-blocks" element={<MartyBlocks />} />
        <Route path="/help" element={<HelpNavigator />} />

        <Route path="*" element={<Navigate to="/" replace />} />

      </Routes>
    </BrowserRouter>
  );
}
