import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MartyConnectionContextProvider } from "./store/marty-connection-context";
import App from "./App";
import DevelopScreen from "./screens/Develop/Develop"
import { StudentContextProvider } from './store/student-context';
import { createStudentData } from './utils/Student';

ReactDOM.render(
    <React.StrictMode>
        <MartyConnectionContextProvider>
            <StudentContextProvider>
                <App />
            </StudentContextProvider>
        </MartyConnectionContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
