import { FunctionComponent, SVGProps } from "react";
import "./styles.css";

interface NavButtonProps {
  title: string;
  ImageComponent: FunctionComponent<SVGProps<SVGSVGElement>>;
  onClick?: () => void;
}

const NavButton = ({ title, ImageComponent, onClick }: NavButtonProps) => {
  return (
    <div className="nav-button-container" onClick={onClick}>
      <div className="nav-button-image-container">
        <ImageComponent />
      </div>
      <p className="nav-button-title">{title}</p>
    </div>
  );
};

export default NavButton;
