import { MartyConnector } from "./MartyConnector";
import { Marty2 as RCSequencer } from "@robotical/rc-sequencer/dist/rn-bridge/mv2-rn";
import { MartyBlocksIF } from "./MartyBlocksConnector";

abstract class AppConnector {
    protected abstract martyConnector: MartyConnector;
    protected abstract _updateTimer: ReturnType<typeof setInterval> | null;
    abstract clearUpdater(): void;
    protected abstract hookupToRicConnector(): void;
    abstract setApp(app: RCSequencer | MartyBlocksIF): void;
    protected abstract _updateSensors(): void;
    protected abstract decodeAndSendMsgToApp(msg: string): void;
    protected abstract unhookFromRicConnector(): void;
}


export default AppConnector;