import {
  AssessmentUserInterface,
  createAssessmentUser,
  AssessmentUserLoginInterface,
  UserUpdateCallBack,
} from '../user/AssessmentUser';
import { gapi } from 'gapi-script';
import { CourseInterface, createCourse, createCourseStruct, Course } from '../firestore/Course';
import { classroom_v1 } from 'googleapis';

const DISCOVERY_DOCS = [
  // "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
  // "https://www.googleapis.com/discovery/v1/apis/people/v1/rest",
  'https://classroom.googleapis.com/$discovery/rest?version=v1',
];
// const SCOPES = "https://www.googleapis.com/auth/drive.metadata.readonly";
export const SCOPES = 'https://www.googleapis.com/auth/classroom.courses.readonly';

export const API_KEY = process.env.REACT_APP_GAPI_API_KEY;
export const CLIENT_ID = process.env.REACT_APP_GAPI_CLIENT_ID;

/**
 * Simple singleton for the google user.
 */
let googleAssessmentUser: AssessmentUserLoginInterface;
export function getGoogleAssessmentUser(): AssessmentUserLoginInterface {
  if (googleAssessmentUser == undefined) {
    googleAssessmentUser = new GoogleAssessmentUser();
  }
  return googleAssessmentUser;
}

/**
 * Provide the required AssessmentUserLoginInterface for use with google classrooms
 */
class GoogleAssessmentUser implements AssessmentUserLoginInterface {
  private initState = -1;
  private listAttendingCourse: CourseInterface[] = [];
  private isAttendingCourseListAvailable = false;
  private runningCourses: CourseInterface[] = [];
  private isRunningCourseListAvailable = false;

  init(changeLoggedInStatus: UserUpdateCallBack): void {
    // Check if already initialised
    if (this.hasBeenInitialised()) {
      return;
    }

    this.initState = 1;
    const self = this;

    const initClientLocal = (callBackFunc: UserUpdateCallBack) => {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        })
        .then(() => {
          // Listen for sign-in state changes.
          const boolCallBackButton = function (status: boolean) {
            const user = self.createUserFromGoogleAuth(gapi.auth2.getAuthInstance());
            callBackFunc(user);
          };

          gapi.auth2.getAuthInstance().isSignedIn.listen(boolCallBackButton);

          // Handle the initial sign-in state.
          const user = self.createUserFromGoogleAuth(gapi.auth2.getAuthInstance());
          callBackFunc(user);
        })
        .catch((err: any) => {
          this.initState = 0;
          console.error('Caught error', err);
        });
    };

    const loadFunc = () => initClientLocal(changeLoggedInStatus);
    gapi.load('client:auth2', loadFunc);
  }

  private createUserFromGoogleAuth(auth2Instance: gapi.auth2.GoogleAuth): AssessmentUserInterface {
    const user = createAssessmentUser();
    user.isLoggedIn = auth2Instance.isSignedIn.get();

    if (user.isLoggedIn) {
      const profile = auth2Instance.currentUser.get().getBasicProfile();
      user.imageUrl = profile.getImageUrl();
      user.name = profile.getName();
      user.id = profile.getId();
    }
    return user;
  }

  signIn() {
    if (this.hasBeenInitialised()) {
      gapi.auth2.getAuthInstance().signIn();
    }
  }

  signOut = () => {
    if (this.hasBeenInitialised()) {
      gapi.auth2.getAuthInstance().signOut();
    }
    // Indicate we don't have list when user signs out.
    this.isAttendingCourseListAvailable = false;
    this.isRunningCourseListAvailable = false;
  };

  runningCourseList = (): Promise<CourseInterface[]> => {
    return new Promise<CourseInterface[]>((resolve, reject) => {
      if (!this.hasBeenInitialised()) {
        reject('Google Interface has not be initialised');
      }
      const signedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
      if (!signedIn) {
        console.log('Reject - no user');
        reject('User is not signed in');
      }

      // If we already have course list then return
      if (this.isRunningCourseListAvailable) {
        resolve(this.runningCourses);
      }

      const classroom = (gapi.client as any).classroom as classroom_v1.Classroom;
      // prepare the list to be filled
      this.runningCourses = [];

      classroom.courses
        .list({
          pageSize: 10,
        })
        .then((coursesList) => {
          // const classroom = ((coursesList as any).result) as classroom_v1.Classroom
          const classroom = (coursesList as any).result as classroom_v1.Schema$ListCoursesResponse;
          const courseList = classroom.courses ?? [];

          const cnt = classroom.courses?.length ?? 0;

          if (courseList.length > 0) {
            for (let i = 0; i < cnt; i++) {
              let course = courseList[i] ?? new Course();
              this.runningCourses.push(this.googleCourseToAssessmentCourse(course));
            }
          }
          // Set flag to indicate we have list
          this.isRunningCourseListAvailable = true;
          resolve(this.runningCourses);
        });
    });
  };

  attendingCourseList = (): Promise<CourseInterface[]> => {
    return new Promise<CourseInterface[]>((resolve, reject) => {
      if (this.listAttendingCourse == undefined) {
        this.listAttendingCourse = [];
      }
      resolve([]);
    });
  };

  private hasBeenInitialised(): boolean {
    if (this.initState < 0) {
      const tokenSet = gapi.client !== undefined;
      this.initState = tokenSet ? 1 : 0;
    }
    return this.initState === 1;
  }
  private googleCourseToAssessmentCourse = (gCourse: classroom_v1.Schema$Course): CourseInterface => {
    const course = new Course();
    course.courseId = gCourse.id ?? '';
    course.name = gCourse.name ?? '';
    return course;
  };
}
