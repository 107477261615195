import modalState from "../../state-observables/modal/ModalState";
import { getStoredAnalyticsConsent } from "./cookies/analytics-consent";
import FirebaseManager from "./models/FirebaseManager";
import AnalyticsConsentModal from "../../components/modals/AnalyticsConsentModal";

export default function analyticsConsentChecking() {
  const consent = getStoredAnalyticsConsent();
  if (consent) {
      FirebaseManager.setAnalyticsConsent(consent);
  } else {
    // consent is not given at all --showing modal to get consent
    modalState.setModal(AnalyticsConsentModal, "HELP US IMPROVE MARTY");
  }
}
