import { useState } from "react";
import modalState from "../../../state-observables/modal/ModalState";
import { DB_URL } from "../../../utils/analytics/bug-report-db-urls";
import randomHashGenerator from "../../../utils/helpers/randomHashGenerator";
import SimpleButton from "../../buttons/SimpleButton";
import "./styles.css";

export default function BugReportModal() {
  const [report, setReport] = useState("");
  const [reportSent, setReportSent] = useState(false);

  const onReport = () => {
    if (report) {
      fetch(DB_URL + randomHashGenerator() + ".json", {
        method: "PATCH",
        headers: {
          Application: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: report }),
      })
        .then((res) => {
          // also sending to slack webhook
          fetch(
            "https://hooks.slack.com/services/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",
            {
              method: "POST",
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
              },
              body: JSON.stringify({ text: report }),
            }
          )
            .then()
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
      setTimeout(() => {
        setReportSent(true);
      }, 500);
      setTimeout(() => {
        modalState.closeModal();
      }, 1500);
    }
  };

  return (
    <div className="bug-report-modal-container">
      {reportSent ? (
        "Thank you for your feedback!"
      ) : (
        <>
          <div className="bug-report-modal-info">
            Please help up improve Marty! This application is in Beta mode, so
            please let us know if you spot any irregularities/bugs. Thank you!
          </div>
          <div className="bug-report-modal-form">
            <textarea
              className="bug-report-modal-text-input"
              onChange={(e) => setReport(e.target.value)}
              value={report}
            />
          </div>
          <div className="bug-report-modal-buttons">
            <SimpleButton
              onClick={() => modalState.closeModal()}
              title="Cancel"
            />
            <SimpleButton onClick={onReport} title="Report" />
          </div>
        </>
      )}
    </div>
  );
}
